import httpService from "./http.service";

const investmentService = {
  get: async (payload) => {
    const { data } = await httpService.get(`investor/investments`, {
      params: payload,
    });
    return data;
  },
  getForAdmin: async (payload) => {
    const { data } = await httpService.get(`admin/investments`, {
      params: payload,
    });
    return data;
  },
  getForMc: async () => {
    const { data } = await httpService.get(`mc/investments`);
    return data;
  },
  post: async (payload) => {
    const { data } = await httpService.post(`investor/create-invest`, payload);
    return data;
  },
  getInvestmentOne: async (payload) => {
    const { data } = await httpService.get(
      `investor/investment/${payload.investId}`
    );
    return data;
  },
  getInvestmentContext: async (payload) => {
    const { data } = await httpService.get(
      `investor/investment-context/${payload.investId}`
    );
    return data;
  },
  approve: async (payload) => {
    const { data } = await httpService.patch(
      `admin/invest/${payload.investId}/approve`
    );
    return data;
  },
  reject: async (payload) => {
    const { data } = await httpService.patch(
      `admin/invest/${payload.investId}/reject`
    );
    return data;
  },
  leave: async (payload) => {
    const { data } = await httpService.patch(
      `investor/investment/${payload.investId}/leave`
    );
    return data;
  },
};

export default investmentService;
