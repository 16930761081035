import { useDispatch, useSelector } from "react-redux";
import { getTypeView } from "../../../store/selectedItem";
import {
  getIsLoadingObject,
  getObjectList,
  loadObjectList,
} from "../../../store/object";
import { getCurrentUser, getUsersFetchingStatus } from "../../../store/users";
import { useEffect } from "react";
import FilterObject from "../../common/filter/FilterObject";
import TypeTable from "../../common/TypeTable";
import TypeCards from "../../common/TypeCards";
import Spinner from "react-bootstrap/Spinner";
import { headers } from "../../common/tableConfig/objectRequests";
import { getObjectStatusesList, getTypeProjectList } from "../../../store/enum";
import { useLocation } from "react-router-dom";
import { transformToFilters } from "../../../utils/utilityFunctions";

const McObjectList = () => {
  const dispatch = useDispatch();
  const typeList = useSelector(getTypeView());
  const objectList = useSelector(getObjectList());
  const isGlobalLoading = useSelector(getUsersFetchingStatus());
  const options = useSelector(getTypeProjectList());
  const objectStatuses = useSelector(getObjectStatusesList());
  const currentUser = useSelector(getCurrentUser());
  const isLoading = useSelector(getIsLoadingObject());
  const location = useLocation();

  useEffect(() => {
    dispatch(
      loadObjectList({
        page: 1,
        pageSize: 20,
        ...transformToFilters(location.search),
      })
    );
  }, [location.search]);

  if (options && objectStatuses && currentUser && objectList)
    return (
      <>
        <div className="container-scroll p-2">
          <div className="container-list">
            <FilterObject />

            {!isLoading
              ? (typeList === "table" && (
                  <TypeTable rows={headers} list={objectList} role="object" />
                )) ||
                (typeList === "cards" && (
                  <TypeCards rows={headers} list={objectList} role="object" />
                ))
              : !isGlobalLoading && (
                  <div
                    className="d-flex m-auto p-0 pt-2 align-items-stretch"
                    style={{
                      width: "calc(100vw - 200px)",
                    }}
                  >
                    <Spinner
                      className="justify-content-center m-auto"
                      animation="border"
                      variant="light"
                    />
                  </div>
                )}
          </div>
        </div>
      </>
    );

  // return (
  //   <>
  //     {!isGlobalLoading && (
  // <div className="container-scroll">
  //   <div
  //     className="d-flex container-form m-auto p-0 pt-2"
  //     style={{
  //       width: "calc(100vw - 40px)",
  //     }}
  //   >
  //     <Spinner
  //       className="justify-content-center m-auto"
  //       animation="border"
  //       variant="dark"
  //     />
  //   </div>
  // </div>
  //     )}
  //   </>
  // );
};

export default McObjectList;
