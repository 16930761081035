import Button from "react-bootstrap/Button";
import { Link, useLocation } from "react-router-dom";

const MenuMc = ({ offcanvas }) => {
  const location = useLocation();

  if (!offcanvas)
    return (
      <div>
        <Button
          variant="link"
          className={`me-3 p-0 ${
            location.pathname === "/my-objects" ? "btn-menu-active" : "btn-menu"
          }
          `}
        >
          <Link className="link-menu" to="/my-objects">
            Мои объекты
          </Link>
        </Button>

        <Button
          variant="link"
          className={`me-3 p-0 ${
            location.pathname === "/add-object" ? "btn-menu-active" : "btn-menu"
          }`}
        >
          <Link className="link-menu" to="/add-object">
            Добавить
          </Link>
        </Button>

        <Button
          variant="link"
          className={`me-3 p-0 ${
            location.pathname === "/object" ? "btn-menu-active" : "btn-menu"
          }`}
        >
          <Link className="link-menu" to="/object">
            Мои заявки
          </Link>
        </Button>

        <Button
          variant="link"
          className={`p-0 ${
            location.pathname === "/investments"
              ? "btn-menu-active"
              : "btn-menu"
          }`}
        >
          <Link className="link-menu" to="/investments">
            Инвестирование
          </Link>
        </Button>
      </div>
    );
  return (
    <>
      <Link className="nav-link-offcanvas" to="/my-objects">
        Мои объекты
      </Link>
      <Link className="nav-link-offcanvas" to="/add-object">
        Добавить объект
      </Link>
      <Link className="nav-link-offcanvas" to="/object">
        Заявки на новые объекты
      </Link>
      <Link className="nav-link-offcanvas" to="/investments">
        Инвестирование
      </Link>
    </>
  );
};

export default MenuMc;
