import React from "react";

const ErrorTooltip = ({ children }) => {
  if (children)
    return (
      <div
        className="p-1 border position-absolute text-danger"
        style={{
          bottom: "-15px",
          lineHeight: "1",
          right: "1px",
          fontSize: "12px",
          backgroundColor: "white",
          zIndex: "1",
        }}
      >
        <p className="m-0">{children}</p>
      </div>
    );
  return;
};

export default ErrorTooltip;
