import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import {
  closeModal,
  getIsModalStatus,
  getModalText,
  getTypeModal,
  openModal,
} from "../../store/modal";
import { approveMc, rejectMc } from "../../store/mc";
import { approveInvestor, rejectInvestor } from "../../store/investor";
import {
  approveObject,
  editObject,
  loadObjectList,
  rejectObject,
  updateObject,
} from "../../store/object";
import {
  approveInvest,
  createInvestment,
  leaveProject,
  loadInvestmentListAdmin,
  rejectInvest,
} from "../../store/investment";
import { useLocation } from "react-router-dom";
import { transformToFilters } from "../../utils/utilityFunctions";

const ModalCustom = () => {
  const dispatch = useDispatch();
  const isActiveModal = useSelector(getIsModalStatus());
  const body = useSelector(getModalText());
  const typeModal = useSelector(getTypeModal());
  const [message, setMessage] = useState("");
  const location = useLocation();

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleApprove = async () => {
    handleClose();

    if (body.item.role === "mc") dispatch(approveMc({ mcId: body.item.id }));
    if (body.item.role === "investor")
      dispatch(approveInvestor({ investorId: body.item.id }));
    if (body.item.role === "object") {
      await dispatch(approveObject({ objectId: body.item.id }));
      if (location.pathname === "/object-request")
        dispatch(
          loadObjectList({
            page: 1,
            pageSize: 20,
            ...transformToFilters(location.search),
          })
        );
    }
    if (body.item.role === "invest") {
      await dispatch(approveInvest({ investId: body.item.id }));
      if (location.pathname === "/investment-request")
        dispatch(
          loadInvestmentListAdmin({
            ...transformToFilters(location.search),
          })
        );
    }
  };

  const handleReject = async () => {
    handleClose();

    if (body.item.role === "mc") dispatch(rejectMc({ mcId: body.item.id }));
    if (body.item.role === "investor")
      dispatch(rejectInvestor({ investorId: body.item.id }));
    if (body.item.role === "object") {
      await dispatch(rejectObject({ objectId: body.item.id }));
      if (location.pathname === "/object-request")
        dispatch(
          loadObjectList({
            page: 1,
            pageSize: 20,
            ...transformToFilters(location.search),
          })
        );
    }
    if (body.item.role === "invest") {
      await dispatch(rejectInvest({ investId: body.item.id }));
      if (location.pathname === "/investment-request")
        dispatch(
          loadInvestmentListAdmin({
            ...transformToFilters(location.search),
          })
        );
    }
  };

  const handleEdit = async () => {
    handleClose();

    if (body.role === "object") {
      await dispatch(editObject({ objectId: body.id, message }));

      dispatch(
        openModal({
          body: "Сообщение с корректировками успешно отправлено компании",
          type: "auth",
        })
      );
    }
  };

  const handleUpdateObject = async () => {
    dispatch(updateObject(body.item));
    dispatch(closeModal());
  };

  const handleInvest = async () => {
    dispatch(closeModal());
    await dispatch(createInvestment(body.item));

    dispatch(
      openModal({
        body: "Ваша заявка на инвестирование успешно отправлена",
        type: "auth",
      })
    );
  };

  const handleLeaveProject = async () => {
    dispatch(closeModal());

    await dispatch(leaveProject({ investId: body.item.id }));

    dispatch(
      openModal({
        body: "Ваша заявка на выход из проекта успешно отправлена администратору",
        type: "auth",
      })
    );
  };

  return (
    <>
      <Modal
        className="d-flex align-items-center justify-content-center"
        show={isActiveModal && typeModal === "confirm"}
        onHide={handleClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body
          className="text-center p-4"
          style={{
            minWidth: "300px",
            color: "#343c4d",
          }}
        >
          <div className="mb-4 ff-demi-22">{body.text}</div>
          <div className="d-flex justify-content-evenly">
            <Button
              className="me-3 btn-contact"
              style={{
                width: "90px",
              }}
              onClick={handleClose}
            >
              Отмена
            </Button>

            <Button
              onClick={
                body.result === true
                  ? handleApprove
                  : body.result === false
                  ? handleReject
                  : body.result === "leaving"
                  ? handleLeaveProject
                  : handleUpdateObject
              }
              className="btn-approve"
              style={{
                width: "90px",
              }}
            >
              Да
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        dialogClassName="modal-edit"
        size="lg"
        className="d-flex align-items-center justify-content-center p-3"
        show={isActiveModal && typeModal === "working"}
        onHide={handleClose}
        style={{
          color: "#343c4d",
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="ff-demi-22"
            // style={{
            //   fontFamily: "Futura PT Heavy",
            // }}
          >
            Сообщение от админа
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center p-3">
          <Form>
            <Form.Group className="mb-3">
              <Form.Control
                as="textarea"
                type="text"
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Опишите, что нужно изменить"
                className="input-object"
                style={{ height: "200px" }}
              />
            </Form.Group>
          </Form>

          <div className="d-flex justify-content-center">
            <Button
              className="me-3 btn-contact"
              style={{
                width: "150px",
              }}
              onClick={handleClose}
            >
              Отмена
            </Button>

            <Button
              disabled={!message}
              type="submit"
              className="me-3 btn-approve"
              style={{
                width: "150px",
              }}
              onClick={handleEdit}
            >
              На доработку
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="d-flex align-items-center justify-content-center"
        show={isActiveModal && typeModal === "auth"}
        style={{
          color: "#343c4d",
        }}
        onHide={handleClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <div className="ff-demi-22">{body}</div>
        </Modal.Body>
      </Modal>

      <Modal
        className="d-flex align-items-center justify-content-center"
        show={isActiveModal && typeModal === "invest"}
        onHide={handleClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body
          className="text-center p-5"
          style={{
            minWidth: "300px",
            color: "#343c4d",
          }}
        >
          <h5 className="mb-4">{body.text}</h5>
          <div className="d-flex justify-content-evenly">
            <Button
              className="me-3 btn-contact"
              style={{
                width: "90px",
              }}
              onClick={handleClose}
            >
              Отмена
            </Button>

            <Button
              className="btn-approve"
              onClick={handleInvest}
              style={{
                width: "90px",
              }}
            >
              Да
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalCustom;
