import httpService from "./http.service";

const objectService = {
  get: async (payload) => {
    const { data } = await httpService.get(`admin/objects`, {
      params: payload,
    });
    return data;
  },
  // getMcObject: async (payload) => {
  //   const { data } = await httpService.get(`mc/object`, {
  //     params: payload,
  //   });
  //   return data;
  // },
  getOneObject: async (payload) => {
    const { data } = await httpService.get(`user/object/${payload.objectId}`);
    return data;
  },
  getObjectContext: async (payload) => {
    const { data } = await httpService.get(
      `user/object-context/${payload.objectId}`
    );
    return data;
  },
  update: async (payload) => {
    const { data } = await httpService.postForm(
      `mc/object/edit/${payload.objectId}`,
      payload.data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  },
  approve: async (payload) => {
    const { data } = await httpService.patch(
      `admin/object/${payload.objectId}/approve`
    );
    return data;
  },
  reject: async (payload) => {
    const { data } = await httpService.patch(
      `admin/object/${payload.objectId}/reject`
    );
    return data;
  },
  work: async (payload) => {
    const { data } = await httpService.patch(
      `admin/object/${payload.objectId}/work`,
      { message: payload.message }
    );
    return data;
  },
  getMcObjects: async (payload) => {
    const { data } = await httpService.get(
      `investor/mc/${payload.mcId}/objects`
    );
    return data;
  },
};

export default objectService;
