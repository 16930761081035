import { useSelector } from "react-redux";
import ProfileAdmin from "./adminUser/ProfileAdmin";
import { getCurrentUser } from "../../store/users";
import ProfileMc from "./mc/ProfileMc";
import ProfileInvestor from "./investor/ProfileInvestor";

const Profile = () => {
  const currentUser = useSelector(getCurrentUser());

  if (currentUser)
    return (
      <>
        {currentUser.role === "admin" && (
          <ProfileAdmin currentUser={currentUser} />
        )}
        {currentUser.role === "mc" && <ProfileMc currentUser={currentUser} />}
        {currentUser.role === "investor" && (
          <ProfileInvestor currentUser={currentUser} />
        )}
      </>
    );
};

export default Profile;
