import { useDispatch, useSelector } from "react-redux";
import TypeCards from "../../common/TypeCards";
import TypeTable from "../../common/TypeTable";
import Spinner from "react-bootstrap/Spinner";
import FilterInvestment from "../../common/filter/FilterInvestment";
import { getTypeView } from "../../../store/selectedItem";
import {
  getInvestmentList,
  getIsLoadingInvestment,
  loadInvestmentList,
} from "../../../store/investment";
import { getCurrentUser, getUsersFetchingStatus } from "../../../store/users";
import { headers } from "../../common/tableConfig/investment";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { transformToFilters } from "../../../utils/utilityFunctions";

const InvestmentList = () => {
  const dispatch = useDispatch();
  const typeList = useSelector(getTypeView());
  const isGlobalLoading = useSelector(getUsersFetchingStatus());
  const isLoading = useSelector(getIsLoadingInvestment());
  const investList = useSelector(getInvestmentList());
  const currentUser = useSelector(getCurrentUser());
  const location = useLocation();

  useEffect(() => {
    // history.push(`/my-investments${transformToQueryParams(filters)}`);
    dispatch(
      loadInvestmentList({
        ...transformToFilters(location.search),
      })
    );
  }, [location.search]);

  if (investList && currentUser)
    return (
      <>
        <div className="container-scroll p-2">
          <div className="container-list">
            <FilterInvestment />

            {!isLoading
              ? (typeList === "table" && (
                  <TypeTable rows={headers} list={investList} role="invest" />
                )) ||
                (typeList === "cards" && (
                  <TypeCards rows={headers} list={investList} role="invest" />
                ))
              : !isGlobalLoading && (
                  <div
                    className="d-flex m-auto p-0 pt-2 align-items-stretch"
                    style={{
                      width: "calc(100vw - 200px)",
                    }}
                  >
                    <Spinner
                      className="justify-content-center m-auto"
                      animation="border"
                      variant="light"
                    />
                  </div>
                )}
          </div>
        </div>
      </>
    );
};

export default InvestmentList;
