import { useEffect } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getIsLoading, getMc } from "../../../store/mc";
import { downloadFiles, getCurrentUser } from "../../../store/users";
import { getObjectListInvested, loadMcObjects } from "../../../store/object";
import { headers } from "../../common/tableConfig/objectShort";
import ImageObject from "../../common/ImageObject";
import Spinner from "react-bootstrap/Spinner";
import { select } from "../../../store/selectedItem";
import { ReactComponent as Download } from "../../../icons/icon-download.svg";
import { ReactComponent as Logo } from "../../../icons/logo_mc.svg";

const McCard = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const mc = useSelector(getMc());
  const isLoading = useSelector(getIsLoading());
  const currentUser = useSelector(getCurrentUser());
  const objects = useSelector(getObjectListInvested());

  useEffect(() => {
    dispatch(loadMcObjects(params));
  }, []);

  useEffect(() => {
    if (mc) {
      dispatch(
        select({
          id: params.mcId,
          role: "mc",
          name: mc.nameCompany,
          email: mc.User.email,
        })
      );
    }
  }, [isLoading]);

  const handleDownload = (e) => {
    e.stopPropagation();
    downloadFiles(mc.Files[0]);
  };

  if (mc && currentUser && objects) {
    const freeObjects = objects.filter((obj) => {
      const invests = obj.Investments.filter(
        (inv) => inv.status === "approved"
      );
      if (!invests.length) {
        return true;
      } else return false;
    });

    const soldoutObjects = objects.filter((obj) => {
      const invests = obj.Investments.filter(
        (inv) => inv.status === "approved"
      );
      if (invests.length) {
        return true;
      } else return false;
    });

    if (
      (currentUser.role === "mc" &&
        params.mcId === String(currentUser.ManagementCompany.id)) ||
      currentUser.role !== "mc"
    )
      return (
        <div className="container-scroll p-2">
          <div
            className="container-list-mc-card bg-white"
            // style={{
            //   minHeight: `${
            //     currentUser.role === "admin" ? "calc(100vh - 86px)" : ""
            //   }`,
            // }}
          >
            <div
              className="ff-heavy-22 px-4 py-3"
              style={{
                color: "#CB9722",
              }}
            >
              <Logo />
              <span className="ms-3">
                Управляющая компания {mc.nameCompany}
              </span>
            </div>

            <hr className="p-0 m-0" />

            <Row className="ff-demi px-4 py-3">
              <Col sm="12" md="4" xl="3" className="d-flex flex-column">
                <div className="mb-2">
                  <div className="mb-2">Сайт компании:</div>
                  <div>
                    <a
                      style={{ color: "#cb9722" }}
                      href={`http://${mc.site}`}
                      target="_blank"
                    >
                      {mc.site}
                    </a>
                  </div>
                </div>

                <div className="mb-2">
                  <div className="mb-2">Презентация компании:</div>
                  <div
                    style={{
                      color: "#cb9722",
                      cursor: "pointer",
                    }}
                    onClick={handleDownload}
                  >
                    <Download />
                    {` `}
                    <span style={{ textDecoration: "underline" }}>
                      Загрузить презентацию
                    </span>
                  </div>
                </div>

                <div className="mb-2">
                  <div className="mb-2">Типы проектов</div>
                  {mc.typeProject.map((type, i) => (
                    <div
                      key={i}
                      className="mb-2 ff-book"
                      style={{
                        lineHeight: "1.3",
                      }}
                    >
                      {type}
                    </div>
                  ))}
                </div>
              </Col>

              <Col sm="6" md="4" xl="2" className="d-flex flex-column">
                <div className="mb-2">
                  <div className="mb-2">ФИО представителя:</div>
                  <div className="ff-book">{mc.employeeName}</div>
                </div>
                <div className="mb-2">
                  <div className="mb-2">Телефон представителя:</div>
                  <div className="ff-book">{mc.User.phone}</div>
                </div>
                <div className="mb-2">
                  <div className="mb-2">E-mail:</div>
                  <div className="ff-book">{mc.User.email}</div>
                </div>
              </Col>

              <Col sm="6" md="4" xl="3" className="d-flex flex-column">
                <div className="mb-2">
                  <div className="mb-2">Telegram:</div>
                  <div className="ff-book">
                    {mc.User.telegram
                      ? mc.User.telegram[0] === "@"
                        ? mc.User.telegram
                        : "@" + mc.User.telegram
                      : "Не указан"}
                  </div>
                </div>
                <div className="mb-2">
                  <div className="mb-2">Должность представителя:</div>
                  <div className="ff-book">{mc.employeePosition}</div>
                </div>
                <div className="mb-2">
                  <div className="mb-2">Количество успешных проектов:</div>
                  <div className="ff-book">{mc.numberProject}</div>
                </div>
              </Col>

              <Col sm="12" md="12" xl="4" className="d-flex flex-column">
                <div className="mb-2">
                  <div className="mb-2">О компании:</div>
                  <div className="ff-book" style={{ whiteSpace: "pre-wrap" }}>
                    {mc.about || "Нет описания"}
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className="container-list-mc-card bg-transparent text-light">
            <div className="m-4 ff-heavy-22">
              Проекты, которые ищут инвестирование
            </div>

            <Row sm={1} md={2} lg={3} xl={3} xxl={4} className="g-2">
              {freeObjects.map((item) => (
                <Col key={item.id}>
                  <Card
                    className="position-relative bg-white border-0 rounded-0"
                    style={{
                      fontSize: "14px",
                      minWidth: "320px",
                    }}
                  >
                    <div
                      className="d-flex position-absolute justify-content-center align-items-center free"
                      style={{
                        height: "35px",
                        width: "28%",
                      }}
                    >
                      <div className="text-uppercase fs-5">Free</div>
                    </div>

                    <ImageObject item={item} />

                    <Card.Body style={{ padding: "2px 0" }}>
                      {headers.map((row) => (
                        <div
                          key={row.header}
                          className={`px-3 d-flex justify-content-between align-items-center ${
                            row.header === "Доходность годовых" && "card-profit"
                          }`}
                          style={{ minHeight: "35px" }}
                        >
                          <div>{row.header}</div>
                          <div>{row.bodyDecorator(item[row.body])}</div>
                        </div>
                      ))}
                    </Card.Body>
                  </Card>
                </Col>
              ))}

              {freeObjects.length === 0 && (
                <h5 style={{ color: "#a9a9a9" }}>Нет проектов</h5>
              )}
            </Row>
          </div>

          <div className="container-list-mc-card bg-transparent text-light">
            <div className="m-4 ff-heavy-22">
              Проекты, которые собрали инвестирование
            </div>

            <Row sm={1} md={2} lg={3} xl={3} xxl={4} className="g-2">
              {soldoutObjects.map((item) => (
                <Col key={item.id}>
                  <Card
                    className="position-relative bg-white rounded-0 border-0"
                    style={{
                      fontSize: "14px",
                      minWidth: "320px",
                    }}
                  >
                    <div
                      className="d-flex position-absolute justify-content-center align-items-center soldout"
                      style={{
                        height: "35px",
                        width: "28%",
                      }}
                    >
                      <div className="text-uppercase fs-5">Sold out</div>
                    </div>

                    <ImageObject item={item} />

                    <Card.Body style={{ padding: "2px 0" }}>
                      {headers.map((row) => (
                        <div
                          key={row.header}
                          className={`px-3 d-flex justify-content-between align-items-center ${
                            row.header === "Доходность годовых" && "card-profit"
                          }`}
                          style={{ minHeight: "35px" }}
                        >
                          <div>{row.header}</div>
                          <div>{row.bodyDecorator(item[row.body])}</div>
                        </div>
                      ))}
                    </Card.Body>
                  </Card>
                </Col>
              ))}

              {soldoutObjects.length === 0 && (
                <h5 style={{ color: "#a9a9a9" }}>Нет проектов</h5>
              )}
            </Row>
          </div>
        </div>
      );
  }

  return (
    <div className="container-scroll">
      <div
        className="d-flex justify-content-center container-form m-auto p-0 pt-2"
        style={{
          width: "calc(100vw - 40px)",
        }}
      >
        <Spinner
          className="justify-content-center m-auto"
          animation="border"
          variant="light"
        />
      </div>
    </div>
  );
};

export default McCard;
