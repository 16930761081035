import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import {
  getDataStatus,
  getIsDataUpdated,
  getIsLoading,
  getMcList,
  loadMcList,
} from "../../../store/mc";
import TypeCards from "../../common/TypeCards";
import TypeTable from "../../common/TypeTable";
import { getUsersFetchingStatus } from "../../../store/users";
import { getTypeView } from "../../../store/selectedItem";
import { headers } from "../../common/tableConfig/mc";
import TypeViewButton from "../../common/TypeViewButton";

const McList = () => {
  const typeList = useSelector(getTypeView());
  const mcList = useSelector(getMcList());
  const loadStatus = useSelector(getDataStatus());
  const isGlobalLoading = useSelector(getUsersFetchingStatus());
  const isLoading = useSelector(getIsLoading());
  const isDataUpdated = useSelector(getIsDataUpdated());
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(loadMcList({ status: "new", page: 1, pageSize: 20 }));
    dispatch(loadMcList({ page: 1, pageSize: 20 }));
  }, [isDataUpdated]);

  if (loadStatus && !isLoading)
    return (
      <div className="container-scroll p-2">
        <div className="container-list">
          {typeList === "table" && <TypeTable rows={headers} list={mcList} />}

          {typeList === "cards" && (
            <>
              <TypeViewButton />
              <TypeCards rows={headers} list={mcList} />
            </>
          )}
        </div>
      </div>
    );

  return (
    <>
      {!isGlobalLoading && (
        <div className="container-scroll">
          <div
            className="d-flex container-form p-0 pt-2"
            style={{
              width: "calc(100vw - 40px)",
            }}
          >
            <Spinner
              className="justify-content-center mx-auto"
              animation="border"
              variant="light"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default McList;
