import enumService from "../services/enum.service";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  entities: null,
  typeProject: null,
  objectStatuses: null,
  investStatuses: null,
  isFetching: false,
  error: null,
  dataLoaded: false,
};

const enumSlice = createSlice({
  name: "enum",
  initialState,
  reducers: {
    typeProjectRequested: (state) => {
      state.error = null;
      state.isFetching = true;
    },
    typeProjectSuccess: (state, action) => {
      state.typeProject = action.payload;
      state.isFetching = false;
      state.dataLoaded = true;
    },
    objectStatusesRequested: (state) => {
      state.error = null;
      state.isFetching = true;
    },
    objectStatusesSuccess: (state, action) => {
      state.objectStatuses = action.payload;
      state.isFetching = false;
      state.dataLoaded = true;
    },
    investStatusesRequested: (state) => {
      state.error = null;
      state.isFetching = true;
    },
    investStatusesSuccess: (state, action) => {
      state.investStatuses = action.payload;
      state.isFetching = false;
      state.dataLoaded = true;
    },
    enumRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isFetching = false;
    },
  },
});

const { reducer: enumReducer, actions } = enumSlice;
const {
  typeProjectRequested,
  typeProjectSuccess,
  enumRequestFailed,
  objectStatusesRequested,
  objectStatusesSuccess,
  investStatusesRequested,
  investStatusesSuccess,
} = actions;

export const loadTypeProjectList = () => async (dispatch) => {
  dispatch(typeProjectRequested());
  try {
    const list = await enumService.getTypeProject();
    dispatch(typeProjectSuccess(list));
  } catch (error) {
    const { message } = error.response.data;
    dispatch(enumRequestFailed(message));
  }
};

export const loadObjectStatusesList = () => async (dispatch) => {
  dispatch(objectStatusesRequested());
  try {
    const list = await enumService.getObjectStatuses();
    dispatch(objectStatusesSuccess(list));
  } catch (error) {
    const { message } = error.response.data;
    dispatch(enumRequestFailed(message));
  }
};

export const loadInvestStatusesList = () => async (dispatch) => {
  dispatch(investStatusesRequested());
  try {
    const list = await enumService.getInvestStatuses();
    dispatch(investStatusesSuccess(list));
  } catch (error) {
    const { message } = error.response.data;
    dispatch(enumRequestFailed(message));
  }
};

export const getTypeProjectList = () => (state) => state.enum.typeProject;
export const getObjectStatusesList = () => (state) => state.enum.objectStatuses;
export const getInvestStatusesList = () => (state) => state.enum.investStatuses;
export const getDataStatus = () => (state) => state.enum.dataLoaded;
export const getIsLoading = () => (state) => state.enum.isFetching;

export default enumReducer;
