import { useLocation } from "react-router-dom";
import Logo from "../../icons/logo-r.svg";

const Footer = () => {
  const location = useLocation();
  const isAuthTheme =
    location.pathname === "/login" ||
    // location.pathname === "/welcome-page" ||
    location.pathname === "/admin/signup" ||
    location.pathname === "/mc/signup" ||
    location.pathname === "/investor/signup" ||
    location.pathname === "/recovery-password-request" ||
    location.pathname === "/recovery-password";

  return (
    <div
      className="position-absolute"
      style={{
        bottom: "20px",
        left: "20px",
        zIndex: `${isAuthTheme ? "1" : "-1"}`,
      }}
    >
      <img src={Logo} height={"80px"} />
    </div>
  );
};

export default Footer;
