import TypeViewButton from "../TypeViewButton";

export const headers = [
  {
    header: "ID",
    body: "id",
    bodyDecorator: (id) => (
      <div className="p-2 pe-0 ff-demi" style={{ color: "#CB9722" }}>
        ID: {id}
      </div>
    ),
  },
  {
    header: "Имя",
    body: "name",
    bodyDecorator: (name) =>
      name === null || name === "" ? (
        <div className="p-2 pe-0 text-secondary">Нет данных</div>
      ) : (
        <div className="p-2 pe-0">{name}</div>
      ),
  },
  {
    header: "E-mail",
    body: "email",
    bodyDecorator: (email) => <div className="p-2 pe-0">{email}</div>,
  },
  {
    header: "Телефон",
    body: "phone",
    bodyDecorator: (phone) => (
      <div className="p-2 pe-0">
        +7 {phone.slice(1, 4)} {phone.slice(4, 7)}-{phone.slice(7, 9)}-
        {phone.slice(9, 11)}
      </div>
    ),
  },

  {
    header: "Telegram",
    body: "telegram",
    bodyDecorator: (tg) =>
      tg === null || tg === "" ? (
        <div className="p-2 pe-0 text-secondary">Нет данных</div>
      ) : (
        <div className="p-2 pe-0">{tg[0] === "@" ? tg : "@" + tg}</div>
      ),
  },
  {
    header: "Статус",
    body: "status",
    headDecorator: () => (
      <div className="d-flex justify-content-between align-items-center">
        <div>Статус</div>
        <TypeViewButton />
      </div>
    ),
    bodyDecorator: (status) => {
      if (status === "new")
        return <div className="px-4 text-center td-status">Новый</div>;
      if (status === "approved")
        return <div className="px-4 text-center td-status">Одобрен</div>;
      if (status === "rejected")
        return <div className="px-4 text-center td-status">Отклонен</div>;
      if (status === "deleted")
        return <div className="px-4 text-center td-status">Удален</div>;
    },
  },
];
