import { Link } from "react-router-dom";
import { normalizeNumber } from "../../../utils/utilityFunctions";
import Button from "react-bootstrap/Button";

export const headers = [
  {
    header: "ФИО инвестора",
    body: "User",
    bodyDecorator: (user) =>
      user.name === null || user.name === "" ? (
        <div className="p-2 text-secondary">Нет данных</div>
      ) : (
        <div className="p-2">{user.name}</div>
      ),
  },
  {
    header: "Телефон",
    body: "User",
    bodyDecorator: (user) => (
      <div className="p-2">
        +7 {user.phone.slice(1, 4)} {user.phone.slice(4, 7)}-
        {user.phone.slice(7, 9)}-{user.phone.slice(9, 11)}
      </div>
    ),
  },
  {
    header: "Объект",
    body: "Object",
    bodyDecorator: (obj) => (
      <div className="p-2">
        <Link to={`/object/${obj.id}`} className="ff-heavy-link">
          <div>{obj.title}</div>
        </Link>
      </div>
    ),
  },
  {
    header: "Мин.объём инвестиций",
    body: "Object",
    bodyDecorator: (obj) => (
      <div className="p-2 ff-demi">{normalizeNumber(obj.minInvest)} ₽</div>
    ),
  },
  {
    header: "Доходность годовых",
    body: "Object",
    bodyDecorator: (obj) => (
      <div className="td-profit ff-heavy">{obj.profit} %</div>
    ),
  },
  {
    header: "Срок запуска проекта",
    body: "Object",
    bodyDecorator: (obj) => <div className="p-2">{obj.dateStart} мес.</div>,
  },
  {
    header: "Тип проекта",
    body: "Object",
    bodyDecorator: (obj) => (
      <Button
        variant="light"
        className="ff-medium"
        style={{
          backgroundColor: "transparent",
          border: 0,
          color: "#343C4D",
          cursor: "auto",
          fontSize: "15px",
        }}
      >
        {obj.typeProject}
      </Button>
    ),
  },
  {
    header: "Статус",
    body: "status",
    bodyDecorator: (status) => {
      if (status === "new")
        return <div className="text-center td-status">Еще не одобрен</div>;
      if (status === "leaving")
        return <div className="text-center td-status">Отменяется</div>;
      if (status === "exit")
        return <div className="text-center td-status">Вышел из проекта</div>;
      if (status === "approved")
        return <div className="text-center td-status">Одобрен</div>;
      if (status === "rejected")
        return <div className="text-center td-status">Отклонен</div>;
    },
  },
];
