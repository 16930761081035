import React from "react";
import MenuAdmin from "./adminUser/MenuAdmin";
import MenuMc from "./mc/MenuMc";
import MenuInvestor from "./investor/MenuInvestor";

const Menu = ({ role, offcanvas = false }) => {
  return (
    <>
      {role === "admin" && <MenuAdmin offcanvas={offcanvas} />}

      {role === "mc" && <MenuMc offcanvas={offcanvas} />}

      {role === "investor" && <MenuInvestor offcanvas={offcanvas} />}
    </>
  );
};

export default Menu;
