import httpService from "./http.service";

const enumService = {
  getTypeProject: async () => {
    const { data } = await httpService.get(`/enum/typeProject`);
    return data;
  },
  getObjectStatuses: async () => {
    const { data } = await httpService.get(`/enum/objectStatuses`);
    return data;
  },
  getInvestStatuses: async () => {
    const { data } = await httpService.get(`/enum/investStatuses`);
    return data;
  },
};

export default enumService;
