import { getTime } from "../../../utils/utilityFunctions";
import { ReactComponent as Read } from "../../../icons/check-all.svg";
import { ReactComponent as Unread } from "../../../icons/check.svg";

const RecipientMessage = ({ message }) => {
  return (
    <div
      className="p-3 mb-2 align-self-end"
      style={{
        backgroundColor: "rgb(203,151,34,0.2)",
        maxWidth: "75%",
        minWidth: "80px",
      }}
    >
      <div className="message-user" style={{ fontSize: "14px" }}>
        {message.message}
      </div>

      <div
        className="text-end"
        style={{
          fontSize: "13px",
        }}
      >
        {getTime(message.createdAt)} {message.read ? <Read /> : <Unread />}
      </div>
    </div>
  );
};

export default RecipientMessage;
