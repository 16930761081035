import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { getIsLoggedIn, getUsersFetchingStatus } from "../../store/users";

const AuthorizedRoute = ({ component: Component, children, ...rest }) => {
  const isLoggedIn = useSelector(getIsLoggedIn());
  const isLoading = useSelector(getUsersFetchingStatus());

  if (!isLoading)
    return (
      <Route
        {...rest}
        render={(props) => {
          if (isLoggedIn) {
            return Component ? <Component {...props} /> : children;
          }
          return (
            <Redirect
              to={{
                pathname: "/welcome-page",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }}
      />
    );
};

export default AuthorizedRoute;
