import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  closeModal,
  getIsModalStatus,
  getTypeModal,
} from "../../../store/modal";
import { getNewMessages } from "../../../store/messages";
import {
  getDate,
  sortById,
  transformToDataParams,
} from "../../../utils/utilityFunctions";
import { useHistory } from "react-router-dom";

const Notifications = () => {
  const isActiveModal = useSelector(getIsModalStatus());
  const typeModal = useSelector(getTypeModal());
  const dispatch = useDispatch();
  const newMessages = useSelector(getNewMessages());
  const history = useHistory();

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleChat = (message) => {
    // console.log(message);
    const path = transformToDataParams(message);
    handleClose();
    history.push(path);
  };

  if (newMessages)
    return (
      <Modal
        dialogClassName="modal-edit"
        scrollable={true}
        className="d-flex align-items-center justify-content-center"
        show={isActiveModal && typeModal === "notifications"}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="ff-demi-22">Новые сообщения</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Object.entries(sortById(newMessages)).map(([key, messages]) => (
            <div
              key={key}
              className="d-flex mb-2 p-1 message-block"
              onClick={() => handleChat(messages[messages.length - 1])}
            >
              <div className="me-3 ff-demi">
                {getDate(messages[messages.length - 1].createdAt)}
              </div>

              <div className="d-flex flex-column message-text">
                <div className="d-flex mb-1">
                  <div className="me-2">
                    <span className="message-icon">{messages.length}</span>{" "}
                    <span className="message-info">{key}</span>
                  </div>
                </div>

                <div className="notification-user">
                  {messages[messages.length - 1].message}
                </div>
              </div>
            </div>
          ))}
        </Modal.Body>
      </Modal>
    );
};

export default Notifications;
