import { useSelector } from "react-redux";
import { getCurrentUser, getIsLoggedIn } from "../../store/users";
import { Redirect } from "react-router-dom";

const Main = () => {
  const isLoggedIn = useSelector(getIsLoggedIn());
  const currentUser = useSelector(getCurrentUser());

  if (isLoggedIn && currentUser) {
    if (currentUser.role === "admin")
      return <Redirect from="/" to="/mc-request" />;
    if (currentUser.role === "mc")
      return <Redirect from="/" to="/add-object" />;
    if (currentUser.role === "investor")
      return <Redirect from="/" to="/my-investments" />;
  }
  if (!isLoggedIn) {
    return <Redirect from="/" to="/welcome-page" />;
  }
};

export default Main;
