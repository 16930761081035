import { normalizeNumber } from "../../../utils/utilityFunctions";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

export const headers = [
  {
    header: "Мин.объём инвестиций",
    body: "minInvest",
    bodyDecorator: (minInvest) => (
      <h4 className="m-0 p-1" style={{ fontFamily: "Futura PT Heavy" }}>
        {normalizeNumber(minInvest)} ₽
      </h4>
    ),
  },
  {
    header: "Полный объём инвестиций",
    body: "maxInvest",
    bodyDecorator: (maxInvest) => (
      <h4 className="m-0 p-1" style={{ fontFamily: "Futura PT Heavy" }}>
        {normalizeNumber(maxInvest)} ₽
      </h4>
    ),
  },
  {
    header: "Доходность годовых",
    body: "profit",
    bodyDecorator: (profit) => (
      <h4
        className="m-0 p-1"
        style={{ color: "#cb9722", fontFamily: "Futura PT Heavy" }}
      >
        {profit} %
      </h4>
    ),
  },
  {
    header: "Срок запуска проекта",
    body: "dateStart",
    bodyDecorator: (dateStart) => <div className="p-2">{dateStart} мес.</div>,
  },
  {
    header: "Тип проекта",
    body: "typeProject",
    bodyDecorator: (item) => (
      <Button
        variant="light"
        style={{
          backgroundColor: "transparent",
          border: 0,
          color: "#343C4D",
          cursor: "auto",
          fontSize: "14px",
        }}
        className="p-2"
      >
        {item}
      </Button>
    ),
  },
  {
    header: "В собственности",
    body: "owner",
    bodyDecorator: (owner) => <div className="p-2">{owner ? "Да" : "Нет"}</div>,
  },
  {
    header: "Возможность продажи",
    body: "sale",
    bodyDecorator: (sale) => <div className="p-2">{sale ? "Да" : "Нет"}</div>,
  },
  {
    header: "Название УК",
    body: "ManagementCompany",
    bodyDecorator: (mc) => (
      <div className="p-2">
        <Link to={`/mc/${mc.id}`} style={{ color: "#343C4D" }}>
          {mc.nameCompany}
        </Link>
      </div>
    ),
  },
  {
    header: "Адрес",
    body: "address",
    bodyDecorator: (address) => (
      <div className="p-2" style={{ maxWidth: "200px" }}>
        {address}
      </div>
    ),
  },
];
