import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isActive: false,
  entities: {},
  type: null,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    modalOpen: (state, action) => {
      state.isActive = true;
      state.entities = action.payload.body;
      state.type = action.payload.type;
    },
    modalClose: (state) => {
      state.isActive = false;
      state.type = null;
    },
  },
});

const { reducer: modalReducer, actions } = modalSlice;
const { modalOpen, modalClose } = actions;

export const openModal = (payload) => (dispatch) => {
  dispatch(modalOpen(payload));
};

export const closeModal = () => (dispatch) => {
  dispatch(modalClose());
};

export const getIsModalStatus = () => (state) => state.modal.isActive;
export const getModalText = () => (state) => state.modal.entities;
export const getTypeModal = () => (state) => state.modal.type;

export default modalReducer;
