import { combineReducers, configureStore } from "@reduxjs/toolkit";
import usersReducer from "./users";
import modalReducer from "./modal";
import mcReducer from "./mc";
import selectedItemReducer from "./selectedItem";
import investorReducer from "./investor";
import enumReducer from "./enum";
import objectReducer from "./object";
import messagesReducer from "./messages";
import investmentReducer from "./investment";

const rootReducer = combineReducers({
  users: usersReducer,
  modal: modalReducer,
  mc: mcReducer,
  investor: investorReducer,
  selectedItem: selectedItemReducer,
  enum: enumReducer,
  object: objectReducer,
  messages: messagesReducer,
  investment: investmentReducer,
});

export function createStore() {
  return configureStore({
    reducer: rootReducer,
  });
}
