import Card from "react-bootstrap/Card";
import config from "../../config.json";

const ImageObject = ({ item }) => {
  const files = item.Files.filter(
    (file) => file?.type?.includes("image") && file?.fileType === "objectPhoto"
  );

  return (
    <Card.Img
      variant="top"
      src={`${config.SERVERLOCAL_URL}${files[0]?.path}`}
      className="rounded-0"
      style={{ height: "250px", objectFit: "cover" }}
    />
  );
};

export default ImageObject;
