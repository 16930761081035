import { useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser, getUsersFetchingStatus } from "../../../store/users";
import { getTypeView } from "../../../store/selectedItem";
import TypeTable from "../../common/TypeTable";
import TypeCards from "../../common/TypeCards";
import { getInvestStatusesList, getTypeProjectList } from "../../../store/enum";
import { getMcNames } from "../../../store/mc";
import { useLocation } from "react-router-dom";
import { transformToFilters } from "../../../utils/utilityFunctions";
import FilterInvestAdmin from "../../common/filter/FilterInvestAdmin";
import {
  getInvestmentList,
  getIsLoadingInvestment,
  loadInvestmentListAdmin,
} from "../../../store/investment";
import { headers } from "../../common/tableConfig/investmentAdmin";

const AdminInvestmentList = () => {
  const dispatch = useDispatch();
  const typeList = useSelector(getTypeView());
  const objectList = useSelector(getInvestmentList());
  const isGlobalLoading = useSelector(getUsersFetchingStatus());
  const options = useSelector(getTypeProjectList());
  const investStatuses = useSelector(getInvestStatusesList());
  const currentUser = useSelector(getCurrentUser());
  const mcNames = useSelector(getMcNames());
  const isLoading = useSelector(getIsLoadingInvestment());
  const location = useLocation();

  useEffect(() => {
    // history.push(`/investment-request${transformToQueryParams(filters)}`);
    dispatch(
      loadInvestmentListAdmin({
        ...transformToFilters(location.search),
      })
    );
  }, [location.search]);

  if (options && investStatuses && currentUser && mcNames && objectList)
    return (
      <>
        <div className="container-scroll p-2">
          <div className="container-list">
            <FilterInvestAdmin />

            {!isLoading
              ? (typeList === "table" && (
                  <TypeTable rows={headers} list={objectList} role="invest" />
                )) ||
                (typeList === "cards" && (
                  <TypeCards rows={headers} list={objectList} role="invest" />
                ))
              : !isGlobalLoading && (
                  <div
                    className="d-flex m-auto p-0 pt-2 align-items-stretch"
                    style={{
                      width: "calc(100vw - 200px)",
                    }}
                  >
                    <Spinner
                      className="justify-content-center m-auto"
                      animation="border"
                      variant="light"
                    />
                  </div>
                )}
          </div>
        </div>
      </>
    );

  // return (
  //   <>
  //     {!isGlobalLoading && (
  //       <div className="container-scroll">
  //         <div
  //           className="d-flex container-form m-auto p-0 pt-2"
  //           style={{
  //             width: "calc(100vw - 40px)",
  //           }}
  //         >
  //           <Spinner
  //             className="justify-content-center m-auto"
  //             animation="border"
  //             variant="dark"
  //           />
  //         </div>
  //       </div>
  //     )}
  //   </>
  // );
};

export default AdminInvestmentList;
