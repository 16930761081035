import { useParams, Route, useHistory } from "react-router-dom";
import FeedbackForm from "./FeedbackForm";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../store/users";

const Chat = () => {
  const params = useParams();
  const history = useHistory();
  const currentUser = useSelector(getCurrentUser());

  if (currentUser) {
    if (currentUser.role === "admin") {
      if (params.context && params.contextId) {
        if (params.context === "object" || params.context === "invest") {
          return (
            <Route
              path="/chat/:context?/:contextId?"
              component={FeedbackForm}
            />
          );
        }
        if (params.context === "mc" || params.context === "investor")
          return (
            <Route
              path="/chat/:context?/:contextId?"
              component={FeedbackForm}
            />
          );

        return history.push("/");
      } else return history.push("/");
    } else {
      if (
        (!params.context && !params.contextId) ||
        ((params.context === "object" || params.context === "invest") &&
          params.contextId)
      ) {
        return (
          <Route path="/chat/:context?/:contextId?" component={FeedbackForm} />
        );
      } else {
        history.push("/");
      }
    }
  }
};

export default Chat;
