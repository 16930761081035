import { normalize } from "../utils/normalizeData";
import httpService from "./http.service";
// import localStorageService from "./localStorage.service";
// const adminEndPoint = "admin/";

const mcService = {
  get: async (payload) => {
    const { data } = await httpService.get(`admin/mc`, {
      params: payload,
    });
    return normalize(data);
  },
  getMcNames: async () => {
    const { data } = await httpService.get(`mc/nameCompany`);
    return data;
  },
  approve: async (payload) => {
    const { data } = await httpService.patch(
      `admin/mc/${payload.mcId}/approve`
    );
    return data;
  },
  reject: async (payload) => {
    const { data } = await httpService.patch(`admin/mc/${payload.mcId}/reject`);
    return data;
  },
  addObject: async (payload) => {
    await httpService.postForm(`mc/add-object`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return;
  },
  getMcOne: async (payload) => {
    const { data } = await httpService.get(`mc/info/${payload.mcId}`);
    return data;
  },
};

export default mcService;
