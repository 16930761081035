import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useLocation } from "react-router-dom";
import { ReactComponent as Approved } from "../../icons/check-lg.svg";
import { ReactComponent as Rejected } from "../../icons/x-lg.svg";
import { ReactComponent as Edit } from "../../icons/pencil-fill.svg";
import { ReactComponent as Chat } from "../../icons/chat-left-fill.svg";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../store/modal";
import { getSelectedItem } from "../../store/selectedItem";
import { Link, useHistory } from "react-router-dom";
import { getIsLoadingInvestor } from "../../store/investor";
import { getIsLoadingObject, getObject, loadObject } from "../../store/object";
import { getIsLoading, getMc, loadMcInfo } from "../../store/mc";
import { getIsLoadingInvestment } from "../../store/investment";

const MenuContact = ({ role }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const objectLoading = useSelector(getIsLoadingObject());
  const mcLoading = useSelector(getIsLoading());
  const investLoading = useSelector(getIsLoadingInvestment());
  const investorLoading = useSelector(getIsLoadingInvestor());
  const isLoading =
    objectLoading || mcLoading || investorLoading || investLoading;
  const item = useSelector(getSelectedItem());

  const mc = useSelector(getMc());
  const object = useSelector(getObject());

  const location = useLocation();
  const isLocation =
    location.pathname === "/mc-request" ||
    /^\/mc\/\d+$/.test(location.pathname) ||
    location.pathname === "/investor-request" ||
    location.pathname === "/object-request" ||
    location.pathname === "/object" ||
    /^\/object\/\d+$/.test(location.pathname) ||
    location.pathname === "/my-objects" ||
    location.pathname === "/investment-request";

  const isObjectPage =
    location.pathname === "/object-request" ||
    location.pathname === "/object" ||
    /^\/object\/\d+$/.test(location.pathname) ||
    location.pathname === "/my-objects";

  const isMcPage =
    location.pathname === "/mc-request" ||
    /^\/mc\/\d+$/.test(location.pathname);

  const isInvestPage = location.pathname === "/my-investments";

  useEffect(() => {
    if (/^\/mc\/\d+$/.test(location.pathname)) {
      dispatch(loadMcInfo({ mcId: location.pathname.split("/")[2] }));
    } else if (/^\/object\/\d+$/.test(location.pathname)) {
      dispatch(loadObject({ objectId: location.pathname.split("/")[2] }));
    }
  }, [location.pathname]);

  const handleApprove = () => {
    dispatch(
      openModal({
        body: {
          item,
          result: true,
          text: `Вы уверены, что хотите одобрить ${
            item.name ||
            item.email ||
            (item.role === "invest" &&
              item.status !== "leaving" &&
              "инвестицию") ||
            (item.role === "invest" &&
              item.status === "leaving" &&
              "выход из проекта") ||
            "заявку с номером " + item.id
          }?`,
        },
        type: "confirm",
      })
    );
  };

  const handleReject = () => {
    dispatch(
      openModal({
        body: {
          item,
          result: false,
          text: `Вы уверены, что хотите отклонить ${
            item.name ||
            item.email ||
            (item.role === "invest" &&
              item.status !== "leaving" &&
              "инвестицию") ||
            (item.role === "invest" &&
              item.status === "leaving" &&
              "выход из проекта") ||
            "заявку с номером " + item.id
          }?`,
        },
        type: "confirm",
      })
    );
  };

  const handleEditObject = () => {
    dispatch(
      openModal({
        body: item,
        type: "working",
      })
    );
  };

  const handleLeaveProject = () => {
    dispatch(
      openModal({
        body: {
          item,
          result: "leaving",
          text: `Вы уверены, что хотите выйти из проекта ${item.titleObject}?`,
        },
        type: "confirm",
      })
    );
  };

  return (
    <>
      {role === "admin" && isLocation && item && (
        <>
          <div className="me-3 button-contact">
            <Button
              className="me-2 btn-approve"
              disabled={
                isLoading ||
                (mc?.User.status === "approved" &&
                  /^\/mc\/\d+$/.test(location.pathname)) ||
                (object?.status === "approved" &&
                  /^\/object\/\d+$/.test(location.pathname)) ||
                item.status === "approved" ||
                item.status === "exit"
              }
              onClick={handleApprove}
            >
              {item.status === "leaving"
                ? "Одобрить выход из проекта"
                : "Утвердить"}
            </Button>
            {isObjectPage && (
              <Button
                className="me-2 btn-contact"
                disabled={isLoading}
                onClick={handleEditObject}
              >
                На доработку
              </Button>
            )}
            <Button
              className="me-2 btn-reject"
              disabled={
                isLoading ||
                (mc?.User.status === "rejected" &&
                  /^\/mc\/\d+$/.test(location.pathname)) ||
                (object?.status === "rejected" &&
                  /^\/object\/\d+$/.test(location.pathname)) ||
                item.status === "rejected" ||
                item.status === "exit"
              }
              onClick={handleReject}
            >
              Отклонить
            </Button>
            {(isObjectPage ||
              isMcPage ||
              location.pathname === "/investor-request" ||
              location.pathname === "/investment-request") && (
              <Button
                className="btn-contact"
                disabled={isLoading}
                onClick={() => history.push(`/chat/${item.role}/${item.id}`)}
              >
                <Chat />
              </Button>
            )}
          </div>

          <div className="me-3 button-contact-compact">
            <Button
              className="me-2 btn-approve"
              disabled={
                isLoading ||
                (mc?.User.status === "approved" &&
                  /^\/mc\/\d+$/.test(location.pathname)) ||
                (object?.status === "approved" &&
                  /^\/object\/\d+$/.test(location.pathname)) ||
                item.status === "approved" ||
                item.status === "exit"
              }
              onClick={handleApprove}
            >
              <Approved />
            </Button>
            {isObjectPage && (
              <Button
                className="me-2 btn-contact"
                disabled={isLoading}
                onClick={handleEditObject}
              >
                <Edit />
              </Button>
            )}
            <Button
              className="me-2 btn-reject"
              disabled={
                isLoading ||
                (mc?.User.status === "rejected" &&
                  /^\/mc\/\d+$/.test(location.pathname)) ||
                (object?.status === "rejected" &&
                  /^\/object\/\d+$/.test(location.pathname)) ||
                item.status === "rejected" ||
                item.status === "exit"
              }
              onClick={handleReject}
            >
              <Rejected />
            </Button>
            {(isObjectPage ||
              isMcPage ||
              location.pathname === "/investor-request") && (
              <Button
                className="btn-contact"
                disabled={isLoading}
                onClick={() => history.push(`/chat/${item.role}/${item.id}`)}
              >
                <Chat />
              </Button>
            )}
          </div>
        </>
      )}

      {role === "mc" && (
        <>
          <div className="me-1 button-contact-mc">
            {isLocation && item && isObjectPage && (
              <>
                <Button
                  className="me-2 px-1 py-0 btn-approve"
                  disabled={isLoading}
                >
                  <Link
                    to={`/object/edit/${item.id}`}
                    className="nav-link text-white"
                  >
                    <Edit />
                  </Link>
                </Button>

                <Button
                  className="me-2 btn-contact"
                  disabled={isLoading}
                  onClick={() => history.push(`/chat/${item.role}/${item.id}`)}
                >
                  Перейти в чат объекта
                </Button>
              </>
            )}

            {!(isLocation && item) && (
              <Button className="m-0 p-0 btn-contact">
                <Link className="nav-link text-white" to="/chat">
                  Связаться с менеджером
                </Link>
              </Button>
            )}

            {/* <Button className="me-2 p-0" style={{ height: "40px" }}>
            <Link
              className="nav-link text-light"
              to="/chat"
              style={{ minWidth: "72px", padding: "6px 8px" }}
            >
              Связаться с менеджером
            </Link>
          </Button> */}
          </div>

          <div className="me-1 button-contact-compact-mc">
            {isLocation && item && (
              <>
                <Button
                  className="me-2 px-1 py-0 btn-approve"
                  disabled={isLoading}
                >
                  <Link
                    to={`/object/edit/${item.id}`}
                    className="nav-link text-white"
                  >
                    <Edit />
                  </Link>
                </Button>

                <Button
                  className="me-2 btn-contact"
                  disabled={isLoading}
                  onClick={() => history.push(`/chat/${item.role}/${item.id}`)}
                >
                  <Chat />
                </Button>
              </>
            )}

            {!(isLocation && item) && (
              <Button className="me-2 p-0 btn-contact">
                <Link className="nav-link text-white" to="/chat">
                  Связаться с менеджером
                </Link>
              </Button>
            )}

            {/* <Button className="me-2 p-0" style={{ height: "40px" }}>
            <Link
              className="nav-link text-light"
              to="/chat"
              style={{ minWidth: "72px", padding: "6px 8px" }}
            >
              Связаться с менеджером
            </Link>
          </Button> */}
          </div>
        </>
      )}

      {role === "investor" && (
        <>
          <div className="me-3 button-contact-mc">
            {isInvestPage && item && (
              <>
                <Button
                  className="me-2 btn-reject"
                  disabled={!(item.status === "approved") || isLoading}
                  onClick={handleLeaveProject}
                >
                  Выйти из проекта
                </Button>

                <Button
                  className="me-2 btn-contact"
                  disabled={isLoading}
                  onClick={() => history.push(`/chat/${item.role}/${item.id}`)}
                >
                  Перейти в чат инвестиции
                </Button>
              </>
            )}
            {!(isInvestPage && item) && (
              <Button className="me-2 p-0 btn-contact">
                <Link
                  className="nav-link text-light"
                  to="/chat"
                  style={{ minWidth: "72px", padding: "6px 8px" }}
                >
                  Связаться с менеджером
                </Link>
              </Button>
            )}
          </div>

          <div className="me-3 button-contact-compact-mc">
            {isInvestPage && item && (
              <>
                <Button
                  className="me-2 btn-reject"
                  disabled={!(item.status === "approved") || isLoading}
                  onClick={handleLeaveProject}
                >
                  <Rejected />
                </Button>
                <Button
                  className="me-2 btn-contact"
                  disabled={isLoading}
                  onClick={() => history.push(`/chat/${item.role}/${item.id}`)}
                >
                  <Chat />
                </Button>
              </>
            )}

            {!(isInvestPage && item) && (
              <Button className="me-2 p-0 btn-contact">
                <Link
                  className="nav-link text-light"
                  to="/chat"
                  style={{ minWidth: "72px", padding: "6px 8px" }}
                >
                  Связаться с менеджером
                </Link>
              </Button>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default MenuContact;
