import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentUser,
  getIsLoggedIn,
  getLoggedUser,
} from "../../store/users";
import { useEffect } from "react";
import {
  loadInvestStatusesList,
  loadObjectStatusesList,
  loadTypeProjectList,
} from "../../store/enum";
import { clearFilters } from "../../store/object";
import { changeViewData, select } from "../../store/selectedItem";
import { loadNewMessage } from "../../store/messages";
import { loadMcNames } from "../../store/mc";
import { clearEntities } from "../../store/investment";

const AppLoader = ({ children }) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn());
  const currentUser = useSelector(getCurrentUser());

  useEffect(() => {
    dispatch(getLoggedUser());
    dispatch(loadTypeProjectList());
    dispatch(loadObjectStatusesList());
    dispatch(loadInvestStatusesList());
    update();
    update.focused = true;

    window.addEventListener("focus", () => {
      update.focused = true;
    });

    window.addEventListener("blur", () => {
      update.focused = false;
    });
  }, []);

  let lastIntervalID = 0;
  function update() {
    setTimeout(() => {
      if (update.focused) {
        dispatch(loadNewMessage());
      }
      clearTimeout(lastIntervalID);
      lastIntervalID = update();
    }, 15000);
  }

  //   let lastIntervalID = 0;

  //   function update() {
  //     setTimeout(() => {
  //       if (isFocused && isLoggedIn) {
  //         dispatch(loadNewMessage());
  //       }
  //       clearTimeout(lastIntervalID);
  //       lastIntervalID = update();
  //     }, 5000);
  //   }

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(loadMcNames());
      dispatch(clearFilters());
      dispatch(changeViewData("table"));
      dispatch(loadNewMessage());
    } else {
      dispatch(select(null));
      dispatch(clearEntities());
    }
  }, [isLoggedIn]);

  return children;
};

export default AppLoader;
