import { createSlice } from "@reduxjs/toolkit";
import messageService from "../services/message.service";
import { useSelector } from "react-redux";
import { getCurrentUser } from "./users";
import localStorageService from "../services/localStorage.service";

const messagesSlice = createSlice({
  name: "messages",
  initialState: {
    messages: null,
    context: null,
    newMessages: null,
    isFetching: false,
    isLoadingChats: false,
    error: null,
    dataLoaded: false,
    updated: false,
  },
  reducers: {
    sendingRequested: (state) => {
      state.error = null;
      state.isFetching = true;
    },
    sendingSuccess: (state) => {
      state.isFetching = false;
    },
    messageRequested: (state) => {
      state.error = null;
      state.isFetching = true;
    },
    messageChatRequested: (state) => {
      state.context = null;
      state.error = null;
      state.isFetching = true;
      state.isLoadingChats = true;
    },
    messageSuccess: (state, action) => {
      state.messages = action.payload.messages;
      state.context = action.payload.context;
      state.isFetching = false;
      state.isLoadingChats = false;
    },
    messageContextSuccess: (state, action) => {
      state.messages = action.payload;
      state.isFetching = false;
      state.isLoadingChats = false;
    },
    newMessageSuccess: (state, action) => {
      state.newMessages = action.payload;
      state.isFetching = false;
    },
    messageFailed: (state, action) => {
      state.error = action.payload;
      state.isFetching = false;
    },
  },
});

const { reducer: messagesReducer, actions } = messagesSlice;
const {
  sendingRequested,
  sendingSuccess,
  messageChatRequested,
  newMessageSuccess,
  messageRequested,
  messageSuccess,
  messageContextSuccess,
  messageFailed,
} = actions;

export const sendContextMessage = (payload) => async (dispatch) => {
  dispatch(sendingRequested());
  try {
    await messageService.postContext(payload);
    const messages = await messageService.getContext(payload.params);
    dispatch(messageContextSuccess(messages));
  } catch (error) {
    console.log(error);
    const { message } = error.response.data;
    dispatch(messageFailed(message));
  }
};

export const getContextMessage = (payload) => async (dispatch) => {
  dispatch(messageChatRequested());
  try {
    const messages = await messageService.getContext(payload);
    dispatch(messageContextSuccess(messages));
    dispatch(loadNewMessage());
  } catch (error) {
    console.log(error);
    dispatch(messageFailed(error.message));
  }
};

export const getMessages = () => async (dispatch) => {
  dispatch(messageChatRequested());
  try {
    const messages = await messageService.get();
    dispatch(messageContextSuccess(messages));
    dispatch(loadNewMessage());
  } catch (error) {
    console.log(error);
    dispatch(messageFailed(error.message));
  }
};

export const getMessagesForAdmin = (payload) => async (dispatch) => {
  dispatch(messageChatRequested());
  try {
    const messages = await messageService.getAdmin(payload);
    dispatch(messageSuccess(messages));
    dispatch(loadNewMessage());
    if (!messages.context) dispatch(messageFailed("Чат не найден"));
  } catch (error) {
    const { message } = error.response.data;
    dispatch(messageFailed(message));
  }
};

export const sendMessage = (payload) => async (dispatch) => {
  dispatch(sendingRequested());
  try {
    await messageService.send(payload);
    const messages = await messageService.get();
    dispatch(messageContextSuccess(messages));
  } catch (error) {
    console.log(error);
    const { message } = error.response.data;
    dispatch(messageFailed(message));
  }
};

export const sendAdminMessage = (payload) => async (dispatch) => {
  dispatch(sendingRequested());
  try {
    await messageService.sendAdmin(payload);
    const messages = await messageService.getAdmin(payload.params);
    dispatch(messageSuccess(messages));
  } catch (error) {
    console.log(error);
    const { message } = error.response.data;
    dispatch(messageFailed(message));
  }
};

export const loadNewMessage = () => async (dispatch) => {
  dispatch(messageRequested());
  try {
    const token = localStorageService.getRefreshToken();
    if (token) {
      const messages = await messageService.getNew();
      dispatch(newMessageSuccess(messages));
    }
  } catch (error) {
    console.log(error);
    dispatch(messageFailed(error.message));
  }
};

export const getMessagesList = () => (state) => state.messages.messages;
export const getMessagesContext = () => (state) => state.messages.context;
export const getIsLoadingMessages = () => (state) => state.messages.isFetching;
export const getNewMessages = () => (state) => state.messages.newMessages;
export const getIsLoadingChat = () => (state) => state.messages.isLoadingChats;
export const getMessageError = () => (state) => state.messages.error;

export default messagesReducer;
