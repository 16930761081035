import Button from "react-bootstrap/Button";
import { Link, useLocation } from "react-router-dom";

const MenuInvestor = ({ offcanvas }) => {
  const location = useLocation();

  if (!offcanvas)
    return (
      <div>
        <Button
          variant="link"
          className={`me-3 p-0 ${
            location.pathname === "/object-request"
              ? "btn-menu-active"
              : "btn-menu"
          }
          `}
        >
          <Link className="link-menu" to="/object-request">
            Поиск объектов
          </Link>
        </Button>

        <Button
          variant="link"
          className={`me-3 p-0 ${
            location.pathname === "/my-investments"
              ? "btn-menu-active"
              : "btn-menu"
          }
          `}
        >
          <Link className="link-menu" to="/my-investments">
            Мои заявки
          </Link>
        </Button>
      </div>
    );
  return (
    <>
      <Link className="nav-link-offcanvas" to="/object-request">
        Поиск объектов
      </Link>
      <Link className="nav-link-offcanvas" to="/my-investments">
        Мои заявки
      </Link>
    </>
  );
};

export default MenuInvestor;
