import investmentService from "../services/investment.service";
import { loadObject } from "./object";
const { createSlice } = require("@reduxjs/toolkit");

export const FILTERS = {
  minInvest: "",
  profit: "",
  dateStart: "",
  typeProject: "",
  owner: "",
  sale: "",
  companyId: "",
  status: "",
};

export const FILTERS_INVESTMENT = {
  waiting: "",
  approved: "",
  deleting: "",
};

const initialState = {
  entities: null,
  filters: FILTERS,
  filtersInvestment: FILTERS_INVESTMENT,
  investment: null,
  isFetching: false,
  error: null,
  dataLoaded: false,
  dataUpdated: false,
};

const investmentSlice = createSlice({
  name: "investment",
  initialState,
  reducers: {
    investmentRequested: (state) => {
      state.error = null;
      state.isFetching = true;
      state.dataUpdated = false;
    },
    investmentRequestSuccess: (state, action) => {
      state.entities = action.payload;
      state.isFetching = false;
      state.dataLoaded = true;
    },
    investmentClearSuccess: (state) => {
      state.entities = null;
      state.isFetching = false;
    },
    investmentOneRequestSuccess: (state, action) => {
      state.investment = action.payload;
      state.isFetching = false;
      state.dataLoaded = true;
    },
    investmentCreateSuccess: (state) => {
      state.isFetching = false;
      state.dataLoaded = true;
    },
    investmentStatusSuccess: (state) => {
      state.isFetching = false;
      state.dataUpdated = true;
    },
    investmentRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isFetching = false;
    },
    filterUpdated: (state, action) => {
      state.filtersInvestment = action.payload;
    },
    filterObjectUpdated: (state, action) => {
      state.filters = action.payload;
    },
  },
});

const { reducer: investmentReducer, actions } = investmentSlice;
const {
  investmentRequested,
  investmentRequestSuccess,
  investmentOneRequestSuccess,
  investmentRequestFailed,
  filterUpdated,
  filterObjectUpdated,
  investmentCreateSuccess,
  investmentStatusSuccess,
  investmentClearSuccess,
} = actions;

export const loadInvestmentList = (payload) => async (dispatch) => {
  dispatch(investmentRequested());
  try {
    const list = await investmentService.get(payload);
    dispatch(investmentRequestSuccess(list));
  } catch (error) {
    const { message } = error.response.data;
    dispatch(investmentRequestFailed(message));
  }
};

export const loadInvestmentListMc = () => async (dispatch) => {
  dispatch(investmentRequested());
  try {
    const list = await investmentService.getForMc();
    dispatch(investmentRequestSuccess(list));
  } catch (error) {
    const { message } = error.response.data;
    dispatch(investmentRequestFailed(message));
  }
};

export const loadInvestmentListAdmin = (payload) => async (dispatch) => {
  dispatch(investmentRequested());
  try {
    const list = await investmentService.getForAdmin(payload);
    dispatch(investmentRequestSuccess(list));
  } catch (error) {
    const { message } = error.response.data;
    dispatch(investmentRequestFailed(message));
  }
};

export const loadInvestment = (payload) => async (dispatch) => {
  dispatch(investmentRequested());
  try {
    const invest = await investmentService.getInvestmentOne(payload);
    dispatch(investmentOneRequestSuccess(invest));
    if (!invest) dispatch(investmentRequestFailed("Инвестиция не найдена"));
  } catch (error) {
    const { message } = error.response.data;
    dispatch(investmentRequestFailed(message));
  }
};

export const loadInvestmentContext = (payload) => async (dispatch) => {
  dispatch(investmentRequested());
  try {
    const invest = await investmentService.getInvestmentContext(payload);
    dispatch(investmentOneRequestSuccess(invest));
    if (!invest) dispatch(investmentRequestFailed("Инвестиция не найдена"));
  } catch (error) {
    const { message } = error.response.data;
    dispatch(investmentRequestFailed(message));
  }
};

export const createInvestment = (payload) => async (dispatch) => {
  dispatch(investmentRequested());
  try {
    await investmentService.post(payload);
    dispatch(loadObject(payload));
    dispatch(investmentCreateSuccess());
  } catch (error) {
    const { message } = error.response.data;
    dispatch(investmentRequestFailed(message));
  }
};

export const leaveProject = (payload) => async (dispatch) => {
  dispatch(investmentRequested());
  try {
    await investmentService.leave(payload);
    dispatch(investmentStatusSuccess());
    const list = await investmentService.get({});
    dispatch(investmentRequestSuccess(list));
  } catch (error) {
    const { message } = error.response.data;
    dispatch(investmentRequestFailed(message));
  }
};

export const approveInvest = (payload) => async (dispatch) => {
  dispatch(investmentRequested());
  try {
    await investmentService.approve(payload);
    dispatch(investmentStatusSuccess());
  } catch (error) {
    const { message } = error.response.data;
    dispatch(investmentRequestFailed(message));
  }
};

export const rejectInvest = (payload) => async (dispatch) => {
  dispatch(investmentRequested());
  try {
    await investmentService.reject(payload);
    dispatch(investmentStatusSuccess());
  } catch (error) {
    const { message } = error.response.data;
    dispatch(investmentRequestFailed(message));
  }
};

export const updateFilterInvestment = (payload) => (dispatch) => {
  dispatch(filterUpdated(payload));
};

export const clearFilterInvestment = () => (dispatch) => {
  dispatch(filterUpdated(FILTERS_INVESTMENT));
};

export const updateFilterObject = (payload) => (dispatch) => {
  dispatch(filterObjectUpdated(payload));
};

export const clearFilterObject = () => (dispatch) => {
  dispatch(filterUpdated(FILTERS));
};

export const clearEntities = () => (dispatch) => {
  dispatch(investmentClearSuccess());
};

export const getInvestmentList = () => (state) => state.investment.entities;
export const getInvestmentInfo = () => (state) => state.investment.investment;
export const getDataStatus = () => (state) => state.investment.dataLoaded;
export const getIsLoadingInvestment = () => (state) =>
  state.investment.isFetching;
export const getIsDataUpdated = () => (state) => state.investment.dataUpdated;
export const getInvestment = () => (state) => state.investment.investmentInfo;
export const getFilters = () => (state) => state.investment.filtersInvestment;
export const getInvestErrors = () => (state) => state.investment.error;
export const getFiltersObject = () => (state) =>
  state.investment.filtersInvestment;

export default investmentReducer;
