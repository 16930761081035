import localStorageService from "../services/localStorage.service";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = localStorageService.getType()
  ? {
      entities: null,
      view: localStorageService.getType(),
    }
  : {
      entities: null,
      view: "table",
    };

const selectedItemSlice = createSlice({
  name: "selectedItem",
  initialState,
  reducers: {
    selected: (state, action) => {
      state.entities = action.payload;
    },
    clear: (state) => {
      state.entities = null;
    },
    changeType: (state, action) => {
      state.view = action.payload;
    },
  },
});

const { reducer: selectedItemReducer, actions } = selectedItemSlice;
const { selected, clear, changeType } = actions;

export const select = (payload) => (dispatch) => {
  dispatch(selected(payload));
};

export const clearItem = () => (dispatch) => {
  dispatch(clear());
};

export const changeViewData = (type) => (dispatch) => {
  dispatch(changeType(type));
  localStorageService.setType(type);
};

export const getSelectedItem = () => (state) => state.selectedItem.entities;
export const getTypeView = () => (state) => state.selectedItem.view;

export default selectedItemReducer;
