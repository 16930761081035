import httpService from "./http.service";
// import localStorageService from "./localStorage.service";
// const userEndPoint = "user/";

const userService = {
  get: async () => {
    const { data } = await httpService.get(`user/me`);
    return data;
  },
  update: async (payload) => {
    const { data } = await httpService.patch(`admin/`, payload);
    return data;
  },
  updateMcUser: async (payload) => {
    const { data } = await httpService.postForm(`mc/`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  },
  updateInvestor: async (payload) => {
    const { data } = await httpService.patch(`investor/`, payload);
    return data;
  },
};

export default userService;
