import Button from "react-bootstrap/Button";
import Logo from "../../icons/logo-rentier.svg";
import { useHistory } from "react-router-dom";

const WelcomePage = () => {
  const history = useHistory();

  return (
    <div className="auth-theme">
      <div className="container d-flex align-items-center justify-content-center vh-100">
        <div className="wrapper bg-transparent d-flex flex-column align-items-center">
          <div className="mb-5">
            <img src={Logo} height={"200px"} />
          </div>
          <Button
            className="btn-auth"
            style={{
              height: "48px",
              width: "300px",
              boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.3)",
            }}
            onClick={() => history.push("/login")}
          >
            Войти
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
