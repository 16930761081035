import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import * as formik from "formik";
import * as yup from "yup";
import { ReactComponent as Eye } from "../../../icons/eye-fill.svg";
import { ReactComponent as EyeSlash } from "../../../icons/eye-slash-fill.svg";
import "../../../App.css";
import { Link } from "react-router-dom";
import YupPassword from "yup-password";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsersFetchingStatus, signUp } from "../../../store/users";
import { openModal } from "../../../store/modal";
import LabelCustom from "../../common/LabelCustom";
import ErrorTooltip from "../../common/ErrorTooltip";
import localStorageService from "../../../services/localStorage.service";
YupPassword(yup);

const RegisterAdmin = () => {
  const { Formik } = formik;
  const isLoading = useSelector(getUsersFetchingStatus());

  const schema = yup.object().shape({
    email: yup
      .string()
      .required("Введите эл. почту")
      .email("Введите эл. почту"),
    password: yup
      .string()
      .required("Введите пароль")
      // .test(
      //   "numbers",
      //   "Допустимы только цифры и лат.буквы",
      //   (value) => !value || /^[0-9a-zA-Z]+$/.test(value)
      // )
      .password()
      .min(8, "Пароль должен содержать не менее 8 символов")
      .minLowercase(1, "Пароль должен содержать хотя бы 1 строчную букву")
      .minUppercase(1, "Пароль должен содержать хотя бы 1 заглавную букву")
      .minNumbers(1, "Пароль должен содержать хотя бы 1 цифру")
      .minSymbols(0),
    phone: yup
      .string()
      .required("Введите номер телефона")
      .password()
      .min(11, "Номер должен начинаться с 8")
      .max(11, "Некорректный номер")
      .minNumbers(11, "Пароль должен состоять из цифр")
      .minSymbols(0)
      .minLowercase(0)
      .minUppercase(0),
  });

  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleSignUp = async (data) => {
    return await dispatch(signUp(data));
  };

  return (
    <div className="auth-theme">
      <div className="container d-flex align-items-center justify-content-center vh-100">
        <div
          className="wrapper bg-white rounded-0"
          style={{
            opacity: `${
              isLoading && localStorageService.getRefreshToken() ? "0" : "1"
            }`,
          }}
        >
          <Formik
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={schema}
            onSubmit={async (values, { resetForm }) => {
              const isReset = await handleSignUp(values);
              if (isReset) {
                resetForm();
                dispatch(
                  openModal({
                    body: "Ваша заявка на регистрацию успешно отправлена",
                    type: "auth",
                  })
                );
              }
            }}
            initialValues={{
              phone: "",
              email: "",
              password: "",
              terms: true,
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
            }) => (
              <Form
                className="p-5 d-flex align-items-center flex-column"
                onSubmit={handleSubmit}
              >
                <h2 className="text-center mb-4" style={{ maxWidth: "500px" }}>
                  Зарегистрировать аккаунт пользователя
                </h2>

                <div className="form__data w-100">
                  <Form.Group
                    className="mb-4 position-relative"
                    controlId="validationFormik01"
                  >
                    <LabelCustom children={"Телефон"} />
                    <Form.Control
                      type="text"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Введите телефон"
                      className="input-auth"
                    />
                    {touched.phone && <ErrorTooltip children={errors.phone} />}
                  </Form.Group>
                  <Form.Group
                    className="mb-4 position-relative"
                    controlId="validationFormik02"
                  >
                    <LabelCustom children={"E-mail"} />
                    <Form.Control
                      type="text"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Введите E-mail"
                      className="input-auth"
                    />
                    {touched.email && <ErrorTooltip children={errors.email} />}
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="validationFormik03">
                    <div className="position-relative">
                      <LabelCustom children={"Пароль"} />
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        placeholder="Введите пароль"
                        className="input-auth"
                      />
                      <Button
                        variant="link"
                        className="p-0 position-absolute end-0 bottom-0 translate-middle link-secondary"
                        onClick={toggleShowPassword}
                      >
                        {!showPassword && <Eye />}
                        {showPassword && <EyeSlash />}
                      </Button>

                      {touched.password && (
                        <ErrorTooltip children={errors.password} />
                      )}
                    </div>
                  </Form.Group>

                  <Button
                    className="btn-auth w-100"
                    style={{
                      height: "48px",
                      marginBottom: "15px",
                    }}
                    type="submit"
                    disabled={!values.terms}
                  >
                    Создать аккаунт
                  </Button>

                  <div className="d-flex justify-content-center px-2 pb-3">
                    <input
                      type="checkbox"
                      className="custom-checkbox"
                      id="terms"
                      name="terms"
                      defaultChecked
                      onChange={handleChange}
                    />
                    <label
                      className="custom-checkbox-label ff-demi"
                      htmlFor="terms"
                      style={{ opacity: "0.7" }}
                    >
                      Я принимаю условия Пользовательского соглашения и даю свое
                      согласие на обработку моей персональной информации на
                      условиях, определенной Политикой конфиденциальности
                    </label>
                  </div>

                  {/* <div className="d-flex justify-content-center">
                  <Form.Check
                    defaultChecked
                    onChange={handleChange}
                    name="terms"
                    style={{
                      width: "85%",
                      fontSize: "11px",
                      marginBottom: "20px",
                    }}
                    type={"checkbox"}
                    id={"default-checkbox"}
                    label={`Я принимаю условия Пользовательского соглашения и даю свое согласие на обработку моей персональной информации на условиях, определенной Политикой конфиденциальности`}
                  />
                </div> */}
                </div>

                <p
                  className="text-center ff-demi"
                  style={{
                    fontSize: "14px",
                  }}
                >
                  Уже есть аккаунт?&nbsp;
                  <Link to={`/login`} className="link-auth">
                    Войти
                  </Link>
                </p>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default RegisterAdmin;
