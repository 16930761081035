import React from "react";
import Spinner from "react-bootstrap/Spinner";
import { useSelector } from "react-redux";
import { getUsersFetchingStatus } from "../../store/users";

const Preloader = () => {
  const isFetching = useSelector(getUsersFetchingStatus());

  return (
    <>
      {isFetching && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            backgroundColor: "grey",
            opacity: "0.5",
            zIndex: "10000",
          }}
        >
          <div
            style={{
              position: "relative",
              top: "50%",
              left: "50%",
            }}
          >
            <Spinner animation="border" variant="light" />
          </div>
        </div>
      )}
    </>
  );
};

export default Preloader;
