import { normalizeNumber } from "../../../utils/utilityFunctions";
import { Link } from "react-router-dom";

export const headersInvest = [
  {
    header: "ФИО инвестора",
    body: "User",
    bodyDecorator: (user) =>
      user.name === null || user.name === "" ? (
        <div className="text-secondary">Нет данных</div>
      ) : (
        <div>{user.name}</div>
      ),
  },
  {
    header: "E-mail",
    body: "User",
    bodyDecorator: (user) => <div>{user.email}</div>,
  },
  {
    header: "Объект",
    body: "Object",
    bodyDecorator: (object) => (
      <Link
        to={`/object/${object.id}`}
        style={{
          color: "#CB9722",
          fontFamily: "Futura PT Heavy",
        }}
      >
        {object.title}
      </Link>
    ),
  },
  {
    header: "Название УК",
    body: "Object",
    bodyDecorator: (object) => (
      <Link
        to={`/mc/${object.ManagementCompany.id}`}
        style={{
          color: "#CB9722",
          fontFamily: "Futura PT Heavy",
        }}
      >
        {object.ManagementCompany.nameCompany}
      </Link>
    ),
  },
  {
    header: "Мин.объём инвестиций",
    body: "Object",
    bodyDecorator: (object) => <div>{normalizeNumber(object.minInvest)} ₽</div>,
  },
  {
    header: "Доходность годовых",
    body: "Object",
    bodyDecorator: (object) => <div>{object.profit} %</div>,
  },
  {
    header: "Срок запуска проекта",
    body: "Object",
    bodyDecorator: (object) => <div>{object.dateStart} мес.</div>,
  },
];

export const headersObject = [
  {
    header: "ФИО представителя",
    body: "ManagementCompany",
    bodyDecorator: (mc) => <div>{mc.employeeName}</div>,
  },
  {
    header: "Объект",
    body: "title",
    bodyDecorator: (title, id) => (
      <div>
        <Link
          to={`/object/${id}`}
          style={{
            color: "#CB9722",
            fontFamily: "Futura PT Heavy",
          }}
        >
          {title}
        </Link>
      </div>
    ),
  },
  {
    header: "Название УК",
    body: "ManagementCompany",
    bodyDecorator: (mc) => (
      <Link
        to={`/mc/${mc.id}`}
        style={{
          color: "#CB9722",
          fontFamily: "Futura PT Heavy",
        }}
      >
        {mc.nameCompany}
      </Link>
    ),
  },

  {
    header: "Мин.объём инвестиций",
    body: "minInvest",
    bodyDecorator: (minInvest) => <div>{normalizeNumber(minInvest)} ₽</div>,
  },
  {
    header: "Доходность годовых",
    body: "profit",
    bodyDecorator: (profit) => <div>{profit} %</div>,
  },
  {
    header: "Срок запуска проекта",
    body: "dateStart",
    bodyDecorator: (dateStart) => <div>{dateStart} мес.</div>,
  },
];

export const headersMc = [
  {
    header: "Название УК",
    body: "nameCompany",
    bodyDecorator: (name, id) => (
      <Link
        to={`/mc/${id}`}
        style={{
          color: "#CB9722",
          fontFamily: "Futura PT Heavy",
        }}
      >
        {name}
      </Link>
    ),
  },
  {
    header: "ФИО представителя",
    body: "User",
    bodyDecorator: (user) => <div>{user.name}</div>,
  },
  {
    header: "E-mail",
    body: "User",
    bodyDecorator: (user) => <div>{user.email}</div>,
  },
];

export const headersInvestor = [
  {
    header: "ФИО инвестора",
    body: "name",
    bodyDecorator: (name) =>
      name === null || name === "" ? (
        <div className="text-secondary">Нет данных</div>
      ) : (
        <div>{name}</div>
      ),
  },
  {
    header: "E-mail",
    body: "email",
    bodyDecorator: (email) => <div>{email}</div>,
  },
];
