const TYPES = [
  { label: "Купля/продажа ком.недвиж.", value: "purchase_sale_commercial" },
  { label: "Субаренда ком.недвиж.", value: "sublease_commercial" },
  { label: "Флиппинг", value: "flipping" },
  { label: "Посут.аренда загород.недвиж.", value: "daily_rent_country" },
  { label: "Посут.аренда жилой недвиж.", value: "daily_rental_residential" },
  { label: "Склады/бытовка", value: "warehouses_cabin" },
  { label: "Земля купля/продажа", value: "purchase_sale_land" },
];

export const optionsTypeProject = (types) => {
  const typeProject = types.map((type) => {
    const filtered = TYPES.filter((item) => item.label === type);

    return {
      label: type,
      value: filtered[0].value,
    };
  });

  return typeProject;
};
