import Form from "react-bootstrap/Form";

const LabelCustom = ({ children, z = null }) => {
  return (
    <Form.Label
      className="p-1 m-0 position-absolute label-auth"
      style={{
        zIndex: `${z}`,
        // bottom: "68%",
      }}
    >
      {children}
    </Form.Label>
  );
};

export default LabelCustom;
