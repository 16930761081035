import Table from "react-bootstrap/Table";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedItem, select } from "../../store/selectedItem";
import { getCurrentUser } from "../../store/users";
import { useLocation } from "react-router-dom";

const TypeTable = ({ rows, list, role = null }) => {
  const clicked = useSelector(getSelectedItem());
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser());
  const location = useLocation();

  const handleClick = ({ id, role, name, email, status, titleObject }) => {
    const isRepeat =
      clicked?.id === id
        ? null
        : { id, role, name, email, status, titleObject };
    dispatch(select(isRepeat));
  };

  useEffect(() => {
    dispatch(select(null));
  }, []);

  if (currentUser)
    return (
      <div className="scroll-table">
        <Table className="m-auto" hover>
          <thead>
            <tr>
              {rows.map((row) => (
                <th
                  style={{
                    color: "#343c4d",
                    fontFamily: "Futura PT Heavy",
                    fontSize: "14px",
                    verticalAlign: "middle",
                    backgroundColor: "#cb9722",
                  }}
                  key={row.header}
                >
                  {row.headDecorator ? row.headDecorator() : row.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {list.map((item) => {
              return (
                ((currentUser.role === "admin" ||
                  (currentUser.role === "mc" &&
                    location.pathname !== "/investments") ||
                  (currentUser.role === "investor" &&
                    location.pathname === "/my-investments")) && (
                  <tr
                    key={item.id}
                    className={clicked?.id === item.id ? "table-secondary" : ""}
                    onClick={(e) => {
                      if (
                        e.target.tagName !== "BUTTON" &&
                        e.target.tagName !== "A"
                      )
                        handleClick({
                          id: item.id,
                          role: item.role || role,
                          name: item.nameCompany,
                          email: item.email,
                          status: item.status,
                          titleObject: item?.Object?.title,
                        });
                    }}
                  >
                    {rows.map((row) => (
                      <td
                        key={row.header}
                        className={`p-0 ${
                          item[row.body] === null || item[row.body] === ""
                            ? "text-secondary"
                            : ""
                        } td-base`}
                        style={{
                          color: "#343c4d",
                          fontFamily: "Futura PT Medium",
                          fontSize: "15px",
                        }}
                      >
                        {row.bodyDecorator(item[row.body])}
                      </td>
                    ))}
                  </tr>
                )) ||
                ((currentUser.role === "investor" ||
                  (currentUser.role === "mc" &&
                    location.pathname === "/investments")) && (
                  <tr key={item.id}>
                    {rows.map((row) => (
                      <td
                        key={row.header}
                        className={`p-0 ${
                          item[row.body] !== null || item[row.body] === ""
                            ? ""
                            : "text-secondary"
                        } td-base`}
                        style={{
                          textWrap: "nowrap",
                          color: "#343c4d",
                          fontFamily: "Futura PT Medium",
                          fontSize: "15px",
                        }}
                      >
                        {row.body === "title"
                          ? row.bodyDecorator(item[row.body], item.id)
                          : row.bodyDecorator(item[row.body])}
                      </td>
                    ))}
                  </tr>
                ))
              );
            })}
          </tbody>
        </Table>
      </div>
    );
};

export default TypeTable;
