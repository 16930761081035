import React, { useState } from "react";
import "../../../App.css";
import { ReactComponent as Eye } from "../../../icons/eye-fill.svg";
import { ReactComponent as EyeSlash } from "../../../icons/eye-slash-fill.svg";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import * as formik from "formik";
import * as yup from "yup";
import YupPassword from "yup-password";
import { useDispatch, useSelector } from "react-redux";
import { getUsersFetchingStatus, resetPassword } from "../../../store/users";
import { openModal } from "../../../store/modal";
import LabelCustom from "../../common/LabelCustom";
import ErrorTooltip from "../../common/ErrorTooltip";
import localStorageService from "../../../services/localStorage.service";
YupPassword(yup);

const ResetPassword = () => {
  const { Formik } = formik;
  const isLoading = useSelector(getUsersFetchingStatus());

  const schema = yup.object().shape({
    password: yup
      .string()
      .required("Введите пароль")
      // .test(
      //   "numbers",
      //   "Допустимы только цифры и лат.буквы",
      //   (value) => !value || /^[0-9a-zA-Z]+$/.test(value)
      // )
      .password()
      .min(8, "Пароль должен содержать не менее 8 символов")
      .minLowercase(1, "Пароль должен содержать хотя бы 1 строчную букву")
      .minUppercase(1, "Пароль должен содержать хотя бы 1 заглавную букву")
      .minNumbers(1, "Пароль должен содержать хотя бы 1 цифру")
      .minSymbols(0),
    passwordValid: yup
      .string()
      .required("Повторите новый пароль")
      .oneOf([yup.ref("password"), null], "Пароли не совпадают"),
  });

  const params = new URLSearchParams(document.location.search);
  const token = params.get("token");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordValid, setShowPasswordValid] = useState(false);
  const dispatch = useDispatch();

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const toggleShowPasswordValid = () => {
    setShowPasswordValid((prevState) => !prevState);
  };

  const handleReset = async (data) => {
    return await dispatch(resetPassword({ password: data.password, token }));
  };

  return (
    <div className="auth-theme">
      <div className="container d-flex align-items-center justify-content-center vh-100">
        <div
          className="wrapper bg-white rounded-0"
          style={{
            opacity: `${
              isLoading && localStorageService.getRefreshToken() ? "0" : "1"
            }`,
          }}
        >
          <Formik
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={schema}
            onSubmit={async (values, { resetForm }) => {
              const isReset = await handleReset(values);
              if (isReset) {
                resetForm();
                dispatch(
                  openModal({
                    body: "Пароль успешно обновлён",
                    type: "auth",
                  })
                );
              }
            }}
            initialValues={{
              password: "",
              passwordValid: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
            }) => (
              <Form
                className="p-5 d-flex align-items-center flex-column"
                onSubmit={handleSubmit}
              >
                <h2 className="text-center mb-4">Введите новый пароль</h2>

                <div className="form__data w-100 mb-2">
                  <Form.Group className="mb-4" controlId="validationFormik01">
                    <div className="position-relative">
                      <LabelCustom children={"Новый пароль"} />
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Введите новый пароль"
                        className="input-auth"
                      />
                      <Button
                        variant="link"
                        className="p-0 position-absolute end-0 bottom-0 translate-middle link-secondary"
                        onClick={toggleShowPassword}
                      >
                        {!showPassword && <Eye />}
                        {showPassword && <EyeSlash />}
                      </Button>

                      {touched.password && (
                        <ErrorTooltip children={errors.password} />
                      )}
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="validationFormik02">
                    <div className="position-relative">
                      <LabelCustom children={"Пароль"} />
                      <Form.Control
                        type={showPasswordValid ? "text" : "password"}
                        name="passwordValid"
                        value={values.passwordValid}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Повторите пароль"
                        className="input-auth"
                      />
                      <Button
                        variant="link"
                        className="p-0 position-absolute end-0 bottom-0 translate-middle link-secondary"
                        onClick={toggleShowPasswordValid}
                      >
                        {!showPasswordValid && <Eye />}
                        {showPasswordValid && <EyeSlash />}
                      </Button>

                      {touched.passwordValid && (
                        <ErrorTooltip children={errors.passwordValid} />
                      )}
                    </div>
                  </Form.Group>

                  <Button
                    className="btn-auth w-100"
                    style={{ height: "48px" }}
                    type="submit"
                  >
                    Сохранить пароль и войти
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
