import { useParams } from "react-router-dom";
import AddObject from "../mc/AddObject";
import { useDispatch, useSelector } from "react-redux";
import { getObject, loadObject } from "../../../store/object";
import { useEffect } from "react";

const EditProfileObject = () => {
  const { objectId } = useParams();
  const dispatch = useDispatch();
  const object = useSelector(getObject());

  useEffect(() => {
    dispatch(loadObject({ objectId }));
  }, []);

  if (object) return <AddObject payload={object} />;
};

export default EditProfileObject;
