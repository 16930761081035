import objectService from "../services/object.service";
export const FILTERS = {
  page: 1,
  pageSize: 20,
  minInvest: "",
  profit: "",
  dateStart: "",
  typeProject: "",
  owner: "",
  sale: "",
  companyId: "",
  status: "",
};

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  entities: null,
  objectInvested: null,
  object: null,
  filters: FILTERS,
  isFetching: false,
  error: null,
  dataLoaded: false,
  dataUpdated: false,
  updated: false,
};

const objectSlice = createSlice({
  name: "object",
  initialState,
  reducers: {
    objectRequested: (state) => {
      state.error = null;
      state.isFetching = true;
      state.dataUpdated = false;
    },
    objectRequestSuccess: (state, action) => {
      state.entities = action.payload;
      state.isFetching = false;
      state.dataLoaded = true;
    },
    objectInvestedRequestSuccess: (state, action) => {
      state.objectInvested = action.payload;
      state.isFetching = false;
      state.dataLoaded = true;
    },
    objectRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isFetching = false;
    },
    objectStatusSuccess: (state) => {
      state.isFetching = false;
      state.dataUpdated = true;
    },
    objectStatusFailed: (state, action) => {
      state.error = action.payload;
      state.isFetching = false;
    },
    filterUpdated: (state, action) => {
      state.filters = action.payload;
    },
    objectOneRequested: (state) => {
      state.error = null;
      state.isFetching = true;
      state.dataUpdated = false;
      state.updated = false;
    },
    objectOneRequestSuccess: (state, action) => {
      state.object = action.payload;
      state.isFetching = false;
      state.dataUpdated = true;
    },
    objectUpdatedSuccess: (state, action) => {
      state.object = action.payload;
      state.isFetching = false;
      state.dataUpdated = true;
      state.updated = "Данные успешно обновлены!";
    },
  },
});

const { reducer: objectReducer, actions } = objectSlice;
const {
  objectRequested,
  objectRequestSuccess,
  objectInvestedRequestSuccess,
  objectRequestFailed,
  objectStatusSuccess,
  objectStatusFailed,
  filterUpdated,
  objectOneRequested,
  objectOneRequestSuccess,
  objectUpdatedSuccess,
} = actions;

export const loadObjectList = (payload) => async (dispatch) => {
  dispatch(objectRequested());
  try {
    const objectList = await objectService.get(payload);
    dispatch(objectRequestSuccess(objectList));
  } catch (error) {
    const { message } = error.response.data;
    dispatch(objectRequestFailed(message));
  }
};

export const loadObject = (payload) => async (dispatch) => {
  dispatch(objectOneRequested());
  try {
    const object = await objectService.getOneObject(payload);
    dispatch(objectOneRequestSuccess(object));
    if (!object) dispatch(objectRequestFailed("Объект не найден"));
  } catch (error) {
    const { message } = error.response.data;
    dispatch(objectRequestFailed(message));
  }
};

export const loadObjectContext = (payload) => async (dispatch) => {
  dispatch(objectOneRequested());
  try {
    const object = await objectService.getObjectContext(payload);
    dispatch(objectOneRequestSuccess(object));
    if (!object) dispatch(objectRequestFailed("Объект не найден"));
  } catch (error) {
    const { message } = error.response.data;
    dispatch(objectRequestFailed(message));
  }
};

export const updateObject = (payload) => async (dispatch) => {
  dispatch(objectOneRequested());
  try {
    await objectService.update(payload);
    const object = await objectService.getOneObject(payload);
    dispatch(objectUpdatedSuccess(object));
  } catch (error) {
    const { message } = error.response.data;
    dispatch(objectRequestFailed(message));
  }
};

export const loadMcObjects = (payload) => async (dispatch) => {
  dispatch(objectRequested());
  try {
    const objectList = await objectService.getMcObjects(payload);
    dispatch(objectInvestedRequestSuccess(objectList));
  } catch (error) {
    const { message } = error.response.data;
    dispatch(objectRequestFailed(message));
  }
};

export const approveObject = (payload) => async (dispatch) => {
  dispatch(objectRequested());
  try {
    await objectService.approve(payload);
    dispatch(objectStatusSuccess());
  } catch (error) {
    const { message } = error.response.data;
    dispatch(objectStatusFailed(message));
  }
};

export const rejectObject = (payload) => async (dispatch) => {
  dispatch(objectRequested());
  try {
    await objectService.reject(payload);
    dispatch(objectStatusSuccess());
  } catch (error) {
    const { message } = error.response.data;
    dispatch(objectStatusFailed(message));
  }
};

export const editObject = (payload) => async (dispatch) => {
  dispatch(objectRequested());
  try {
    await objectService.work(payload);
    dispatch(objectStatusSuccess());
  } catch (error) {
    const { message } = error.response.data;
    dispatch(objectStatusFailed(message));
  }
};

export const updateFilters = (payload) => (dispatch) => {
  dispatch(filterUpdated(payload));
};

export const clearFilters = () => (dispatch) => {
  dispatch(filterUpdated(FILTERS));
};

export const getObjectList = () => (state) => state.object.entities;
export const getObjectListInvested = () => (state) =>
  state.object.objectInvested;
export const getObject = () => (state) => state.object.object;
export const getDataStatus = () => (state) => state.object.dataLoaded;
export const getIsLoadingObject = () => (state) => state.object.isFetching;
export const getIsDataUpdated = () => (state) => state.object.dataUpdated;
export const getFilters = () => (state) => state.object.filters;
export const getObjectUpdated = () => (state) => state.object.updated;
export const getObjectErrors = () => (state) => state.object.error;

export default objectReducer;
