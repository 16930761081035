import Button from "react-bootstrap/Button";
import { ReactComponent as Grid } from "../../icons/type-card.svg";
import { ReactComponent as Table } from "../../icons/type-table.svg";
import { useDispatch, useSelector } from "react-redux";
import { changeViewData, getTypeView } from "../../store/selectedItem";

const TypeViewButton = () => {
  const typeView = useSelector(getTypeView());
  const dispatch = useDispatch();

  return (
    <>
      {typeView === "cards" && (
        <div
          className="d-flex justify-content-end p-2"
          style={{
            height: "43px",
            margin: "0 6px",
            marginBottom: "6px",
            backgroundColor: "#CB9722",
          }}
        >
          <Button
            className="p-0 px-1 link-light"
            variant="link"
            onClick={() => dispatch(changeViewData("table"))}
          >
            <Table />
          </Button>
        </div>
      )}

      {typeView === "table" && (
        <Button
          className="p-0 px-1 link-light"
          variant="link"
          onClick={() => dispatch(changeViewData("cards"))}
        >
          <Grid />
        </Button>
      )}
    </>
  );
};

export default TypeViewButton;
