import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { ReactComponent as Eye } from "../../icons/eye-fill.svg";
import { ReactComponent as EyeSlash } from "../../icons/eye-slash-fill.svg";
import "../../App.css";
import * as formik from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAuthErrors,
  getIsLoggedIn,
  getUsersFetchingStatus,
  signIn,
} from "../../store/users";
import * as yup from "yup";
import YupPassword from "yup-password";
import ErrorTooltip from "../common/ErrorTooltip";
import LabelCustom from "../common/LabelCustom";
import { Link, useHistory } from "react-router-dom";
import localStorageService from "../../services/localStorage.service";
YupPassword(yup);

const Login = () => {
  const { Formik } = formik;
  const isLoggedIn = useSelector(getIsLoggedIn());
  const isLoading = useSelector(getUsersFetchingStatus());
  const history = useHistory();
  const [clicked, setClicked] = useState(false);
  const error = useSelector(getAuthErrors());

  const schema = yup.object().shape({
    email: yup
      .string()
      .required("Введите эл. почту")
      .email("Введите эл. почту"),
    password: yup.string().required("Введите пароль"),
  });

  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleLogin = (data) => {
    dispatch(signIn(data));
  };

  return (
    <div className="auth-theme">
      <div className="container d-flex align-items-center justify-content-center vh-100">
        <div
          className="wrapper bg-white login-form"
          style={{
            opacity: `${
              isLoading && localStorageService.getRefreshToken() ? "0" : "1"
            }`,
          }}
        >
          <Formik
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={schema}
            onSubmit={handleLogin}
            initialValues={{
              email: "",
              password: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
            }) => (
              <Form
                className="p-5 d-flex align-items-center flex-column"
                onSubmit={(e) => {
                  handleSubmit(e);
                  setClicked(false);
                }}
              >
                <h2
                  className="text-center mb-4 p-3"
                  style={{ maxWidth: "361px" }}
                >
                  Вход в личный кабинет
                </h2>

                <div className="form__data w-100 mb-2">
                  <Form.Group
                    className="mb-4 position-relative"
                    controlId="validationFormik01"
                  >
                    <LabelCustom children={"E-mail"} />
                    <Form.Control
                      type="text"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Введите e-mail"
                      className="input-auth"
                    />
                    {touched.email && <ErrorTooltip children={errors.email} />}
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="validationFormik02">
                    <div className="position-relative">
                      <LabelCustom children={"Пароль"} />
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Введите пароль"
                        className={`input-auth ${
                          error && !clicked ? "d-none" : ""
                        }`}
                      />
                      {error && (
                        <Form.Control
                          type="text"
                          readOnly
                          className={`input-auth-error ${
                            clicked ? "d-none" : ""
                          }`}
                          value={error}
                          onClick={() => {
                            setClicked(true);
                            document
                              .getElementById("validationFormik01")
                              .focus();
                          }}
                        />
                      )}
                      <Button
                        variant="link"
                        className="p-0 position-absolute end-0 bottom-0 translate-middle link-secondary"
                        onClick={toggleShowPassword}
                      >
                        {!showPassword && <Eye />}
                        {showPassword && <EyeSlash />}
                      </Button>

                      {touched.password && (
                        <ErrorTooltip children={errors.password} />
                      )}
                    </div>
                  </Form.Group>

                  <Button
                    className="btn-auth w-100"
                    style={{
                      height: "48px",
                      boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.3)",
                    }}
                    type="submit"
                    disabled={error && !clicked}
                  >
                    Войти
                  </Button>
                </div>

                <p className="text-center">
                  <Button variant="link">
                    <Link to="/recovery-password-request" className="link-auth">
                      Забыли пароль?
                    </Link>
                  </Button>
                </p>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Login;
