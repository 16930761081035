import { useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsLoadingObject,
  getObjectList,
  loadObjectList,
} from "../../../store/object";
import { getCurrentUser, getUsersFetchingStatus } from "../../../store/users";
import { getTypeView } from "../../../store/selectedItem";
import TypeTable from "../../common/TypeTable";
import { headers, headersInvestor } from "../../common/tableConfig/object";
import TypeCards from "../../common/TypeCards";
import FilterObject from "../../common/filter/FilterObject";
import { getObjectStatusesList, getTypeProjectList } from "../../../store/enum";
import { getMcNames } from "../../../store/mc";
import { useLocation } from "react-router-dom";
import { transformToFilters } from "../../../utils/utilityFunctions";

const ObjectList = () => {
  const dispatch = useDispatch();
  const typeList = useSelector(getTypeView());
  const objectList = useSelector(getObjectList());
  const isGlobalLoading = useSelector(getUsersFetchingStatus());
  const options = useSelector(getTypeProjectList());
  const objectStatuses = useSelector(getObjectStatusesList());
  const currentUser = useSelector(getCurrentUser());
  const mcNames = useSelector(getMcNames());
  const isLoading = useSelector(getIsLoadingObject());
  const location = useLocation();

  useEffect(() => {
    dispatch(
      loadObjectList({
        page: 1,
        pageSize: 20,
        ...transformToFilters(location.search),
      })
    );
  }, [location.search]);

  if (options && objectStatuses && currentUser && mcNames && objectList) {
    const formatedObjectList =
      currentUser.role === "investor"
        ? objectList.filter(
            (obj) => obj.status === "approved" && obj.sale === true
            // {
            //   const invests = obj.Investments.filter(
            //     (inv) => inv.status === "approved"
            //   );
            //   if (!invests.length && obj.status === "approved") {
            //     return true;
            //   } else return false;
            // }
          )
        : objectList;

    return (
      <>
        <div className="container-scroll p-2">
          <div className="container-list">
            <FilterObject />

            {!isLoading
              ? (typeList === "table" && (
                  <TypeTable
                    rows={
                      currentUser.role === "investor"
                        ? headersInvestor
                        : headers
                    }
                    list={formatedObjectList}
                    role="object"
                  />
                )) ||
                (typeList === "cards" && (
                  <TypeCards
                    rows={
                      currentUser.role === "investor"
                        ? headersInvestor
                        : headers
                    }
                    list={formatedObjectList}
                    role="object"
                  />
                ))
              : !isGlobalLoading && (
                  <div
                    className="d-flex m-auto p-0 pt-2 align-items-stretch"
                    style={{
                      width: "calc(100vw - 200px)",
                    }}
                  >
                    <Spinner
                      className="justify-content-center m-auto"
                      animation="border"
                      variant="light"
                    />
                  </div>
                )}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {!isGlobalLoading && (
        <div className="container-scroll">
          <div
            className="d-flex container-form m-auto p-0 pt-2"
            style={{
              width: "calc(100vw - 40px)",
            }}
          >
            <Spinner
              className="justify-content-center m-auto"
              animation="border"
              variant="light"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ObjectList;
