import axios from "axios";
import config from "../config.json";
import localStorageService from "./localStorage.service";

const httpAuth = axios.create({
  baseURL: config.SERVERLOCAL_URL + "api/auth/",
  params: {},
});

const authService = {
  register: async (payload) => {
    const { data } = await httpAuth.post(`admin/signUp`, payload);
    return data;
  },
  login: async ({ email, password }) => {
    const { data } = await httpAuth.post(`login`, {
      email,
      password,
    });
    return data;
  },
  refresh: async () => {
    const data = await httpAuth.post("refresh-token", {
      grant_type: "refresh_token",
      refresh_token: localStorageService.getRefreshToken(),
    });
    return data;
  },
  recoverPassword: async ({ email }) => {
    const { data } = await httpAuth.post(`password-recovery-request`, {
      email,
    });
    return data;
  },
  resetPassword: async (payload) => {
    const { data } = await httpAuth.patch(
      `password-recovery`,
      { password: payload.password },
      {
        params: {
          token: payload.token,
        },
      }
    );
    return data;
  },
  registerMc: async (payload) => {
    const data = await httpAuth.postForm(`mc/signup`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  },
  registerInvestor: async (payload) => {
    const { data } = await httpAuth.post(`investor/signUp`, payload);
    return data;
  },
};

export default authService;
