import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import TypeViewButton from "../TypeViewButton";
import Dropdown from "react-bootstrap/Dropdown";
import localStorageService from "../../../services/localStorage.service";

export const headers = [
  {
    header: "ID",
    body: "id",
    bodyDecorator: (id) =>
      localStorageService.getType() === "cards" ? (
        <Link to={`/mc/${id}`} className="p-0 ff-heavy-22">
          ID: {id}
        </Link>
      ) : (
        <div className="p-2">
          <Link to={`/mc/${id}`} className="ff-heavy-link">
            ID: {id}
          </Link>
        </div>
      ),
  },
  {
    header: "Название",
    body: "nameCompany",
    bodyDecorator: (nameCompany) => <div className="p-2">{nameCompany}</div>,
  },
  {
    header: "ИНН",
    body: "inn",
    bodyDecorator: (inn) => <div className="p-2">{inn}</div>,
  },
  {
    header: "Сайт компании",
    body: "site",
    bodyDecorator: (site) => <div className="p-2">{site}</div>,
  },
  {
    header: "Типы проектов",
    body: "typeProject",
    bodyDecorator: (items) => (
      <Dropdown>
        {items.length !== 1 && (
          <>
            <Dropdown.Toggle
              variant="light"
              id="dropdown-basic"
              style={{
                backgroundColor: "transparent",
                border: 0,
                color: "#343C4D",
                fontSize: `${
                  localStorageService.getType() === "cards" ? "14px" : "15px"
                }`,
              }}
              className={`p-2 pe-0 ${
                localStorageService.getType() === "cards"
                  ? "ff-demi"
                  : "ff-medium"
              }`}
            >
              {items[0]}
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu dropdown-menu-end rounded-0 mt-0">
              {items.map(
                (item, i) =>
                  i !== 0 && (
                    <Dropdown.Item
                      key={i}
                      style={{
                        color: "#343C4D",
                        fontSize: `${
                          localStorageService.getType() === "cards"
                            ? "14px"
                            : "15px"
                        }`,
                      }}
                      as="button"
                      disabled
                      className={`p-2 pe-0 ${
                        localStorageService.getType() === "cards"
                          ? "ff-demi"
                          : "ff-medium"
                      }`}
                    >
                      {item}
                    </Dropdown.Item>
                  )
              )}
            </Dropdown.Menu>
          </>
        )}

        {items.length === 1 && (
          <Button
            variant="light"
            style={{
              backgroundColor: "transparent",
              border: 0,
              color: "#343C4D",
              cursor: "auto",
              fontSize: `${
                localStorageService.getType() === "cards" ? "14px" : "15px"
              }`,
            }}
            className={`p-2 pe-0 ${
              localStorageService.getType() === "cards"
                ? "ff-demi"
                : "ff-medium"
            }`}
          >
            {items[0]}
          </Button>
        )}
      </Dropdown>
    ),
  },
  {
    header: "ФИО представителя",
    body: "employeeName",
    bodyDecorator: (employeeName) => <div className="p-2">{employeeName}</div>,
  },
  {
    header: "Телефон",
    body: "phone",
    bodyDecorator: (phone) => (
      <div className="p-2">
        +7 {phone.slice(1, 4)} {phone.slice(4, 7)}-{phone.slice(7, 9)}-
        {phone.slice(9, 11)}
      </div>
    ),
  },
  {
    header: "E-mail",
    body: "email",
    bodyDecorator: (email) => <div className="p-2">{email}</div>,
  },
  {
    header: "Должность",
    body: "employeePosition",
    bodyDecorator: (employeePosition) => (
      <div className="p-2">{employeePosition}</div>
    ),
  },
  {
    header: "Статус",
    body: "status",
    headDecorator: () => (
      <div className="d-flex justify-content-between align-items-center">
        <div>Статус</div>
        <TypeViewButton />
      </div>
    ),
    bodyDecorator: (status) => {
      if (status === "new")
        return <div className="px-4 text-center td-status">Новый</div>;
      if (status === "approved")
        return <div className="px-4 text-center td-status">Одобрен</div>;
      if (status === "rejected")
        return <div className="px-4 text-center td-status">Отклонен</div>;
      if (status === "deleted")
        return <div className="px-4 text-center td-status">Удален</div>;
    },
  },
];
