import React, { useEffect, useState } from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { useSelector } from "react-redux";
import { getAuthErrors, getUserUpdated } from "../../store/users";
import { getObjectErrors, getObjectUpdated } from "../../store/object";
import { getMessageError } from "../../store/messages";
import { getMcErrors } from "../../store/mc";
import { getInvestErrors } from "../../store/investment";
import { useLocation } from "react-router-dom";

const Toasts = () => {
  const error = useSelector(getAuthErrors());
  const errorObject = useSelector(getObjectErrors());
  const errorChat = useSelector(getMessageError());
  const errorMc = useSelector(getMcErrors());
  const errorInvest = useSelector(getInvestErrors());
  const updatedUser = useSelector(getUserUpdated());
  const updatedObject = useSelector(getObjectUpdated());
  const [show, setShow] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setShow(true);
  }, [
    error,
    errorObject,
    errorChat,
    errorMc,
    errorInvest,
    updatedUser,
    updatedObject,
  ]);

  if (
    (error && location.pathname !== "/login") ||
    errorObject ||
    errorChat ||
    errorMc ||
    errorInvest
  )
    return (
      <ToastContainer className="mt-2" position={"bottom-end"}>
        <Toast
          className="d-inline-block m-1"
          onClose={() => setShow(false)}
          show={show}
          delay={5000}
          autohide
        >
          <Toast.Body className="text-danger">
            <strong>
              {error || errorObject || errorChat || errorMc || errorInvest}
            </strong>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    );

  if (updatedUser || updatedObject)
    return (
      <ToastContainer className="mt-2" position={"bottom-end"}>
        <Toast
          className="d-inline-block m-1"
          onClose={() => setShow(false)}
          show={show}
          delay={5000}
          autohide
        >
          <Toast.Body className="text-dark">
            <strong>{updatedUser || updatedObject}</strong>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    );
};

export default Toasts;
