import { normalize } from "../utils/normalizeData";
import httpService from "./http.service";

const investorService = {
  get: async (payload) => {
    const { data } = await httpService.get(`admin/investor`, {
      params: payload,
    });
    // console.log(data);
    // console.log(normalize(data));
    return normalize(data);
  },
  approve: async (payload) => {
    const { data } = await httpService.patch(
      `admin/investor/${payload.investorId}/approve`
    );
    return data;
  },
  reject: async (payload) => {
    const { data } = await httpService.patch(
      `admin/investor/${payload.investorId}/reject`
    );
    return data;
  },
  getInvestorOne: async (payload) => {
    const { data } = await httpService.get(
      `investor/info/${payload.investorId}`
    );
    return data;
  },
};

export default investorService;
