import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import { useEffect, useRef, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { ReactComponent as SendIcon } from "../../../icons/send.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getContextMessage,
  getIsLoadingChat,
  getIsLoadingMessages,
  getMessages,
  getMessagesContext,
  getMessagesForAdmin,
  getMessagesList,
  loadNewMessage,
  sendAdminMessage,
  sendContextMessage,
  sendMessage,
} from "../../../store/messages";
import {
  getDate,
  getTextContext,
  splitByKey,
} from "../../../utils/utilityFunctions";
import { getCurrentUser } from "../../../store/users";
import {
  getIsLoadingObject,
  getObject,
  getObjectErrors,
  loadObjectContext,
} from "../../../store/object";
import SenderMessage from "./SenderMessage";
import RecipientMessage from "./RecipientMessage";
import {
  getInvestErrors,
  getInvestmentInfo,
  getIsLoadingInvestment,
  loadInvestmentContext,
} from "../../../store/investment";
import {
  headersInvest,
  headersInvestor,
  headersMc,
  headersObject,
} from "../tableConfig/objectChat";

const FeedbackForm = () => {
  const params = useParams();
  const location = useLocation();
  const [textMessage, setTextMessage] = useState("");
  const chatRef = useRef(null);
  const chatMobileRef = useRef(null);
  const [focused, setFocused] = useState(false);
  const dispatch = useDispatch();
  const messages = useSelector(getMessagesList());
  const currentUser = useSelector(getCurrentUser());
  const object = useSelector(getObject());
  const investment = useSelector(getInvestmentInfo());
  const isObjectLoading = useSelector(getIsLoadingObject());
  const isInvestLoading = useSelector(getIsLoadingInvestment());
  const isChatLoading = useSelector(getIsLoadingChat());
  const contextMessage = useSelector(getMessagesContext());
  const errorInvest = useSelector(getInvestErrors());
  const errorObject = useSelector(getObjectErrors());

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  useEffect(() => {
    if (messages) {
      chatRef?.current?.scrollTo({
        top: 99999,
        behavior: "auto",
      });
      chatMobileRef?.current?.scrollTo({
        top: 99999,
        behavior: "auto",
      });
    }
  }, [focused, messages]);

  useEffect(() => {
    if (!params?.context) {
      dispatch(getMessages());
    } else if (params.context === "mc") {
      dispatch(getMessagesForAdmin(params));
    } else if (params.context === "investor") {
      dispatch(getMessagesForAdmin(params));
    } else if (params.context === "object") {
      dispatch(loadObjectContext({ objectId: params.contextId }));
      dispatch(getContextMessage(params));
    } else if (params.context === "invest") {
      dispatch(loadInvestmentContext({ investId: params.contextId }));
      dispatch(getContextMessage(params));
    }
  }, [location.pathname]);

  const handleSend = () => {
    setTextMessage("");

    if (!params?.context) {
      dispatch(sendMessage({ textMessage }));
    } else if (params.context === "object" || params.context === "invest") {
      dispatch(sendContextMessage({ params, data: { textMessage } }));
    } else {
      dispatch(sendAdminMessage({ params, data: { textMessage } }));
    }

    dispatch(loadNewMessage());
  };

  if (
    messages &&
    currentUser &&
    !isObjectLoading &&
    !isInvestLoading &&
    !errorInvest &&
    !errorObject
  ) {
    const messagesGrouped = splitByKey(
      messages.map((e) => ({ ...e, dateKey: getDate(e.createdAt) })),
      "dateKey"
    );

    if (
      !isChatLoading &&
      ((currentUser.role === "admin" &&
        ((params.context === "mc" && contextMessage?.nameCompany) ||
          (params.context === "investor" && contextMessage?.email) ||
          (params.context === "object" && object) ||
          (params.context === "invest" && investment))) ||
        (currentUser.role !== "admin" &&
          ((params.context === "object" && object) ||
            (params.context === "invest" && investment))) ||
        currentUser.role !== "admin")
    ) {
      const isRecipient = (m) => {
        return (
          (currentUser.role !== "admin" && m.senderId === currentUser.id) ||
          (currentUser.role === "admin" &&
            ((params.context === "mc" &&
              m.senderId !== Number(contextMessage.userId)) ||
              (params.context === "investor" &&
                m.senderId !== Number(params.contextId)))) ||
          (currentUser.role === "admin" &&
            params.context === "object" &&
            m.senderId !== object.ManagementCompany.userId) ||
          (currentUser.role === "admin" &&
            params.context === "invest" &&
            m.senderId !== investment.userId)
        );
      };

      return (
        <>
          {/* classic chatForm (>767px) */}
          <div className="p-2 chatFormClassic">
            <div
              className="container-list bg-white"
              style={{ color: "#343c4d" }}
            >
              <Row className="m-0 p-3">
                <Col sm="12">
                  <h4
                    className="m-0"
                    style={{
                      fontFamily: "Futura PT Heavy",
                      color: "#CB9722",
                    }}
                  >
                    Обратная связь{getTextContext(params?.context)}
                  </h4>
                  <hr />
                </Col>

                <Col md="4">
                  {params.context === "object" && object && (
                    <Card className="border-0 card-chat">
                      <Card.Body className="p-0">
                        {headersObject.map((row) => {
                          if (
                            currentUser.role === "mc" &&
                            row.body === "ManagementCompany"
                          ) {
                            return;
                          }
                          return (
                            <div
                              key={row.header}
                              className="d-flex justify-content-between align-items-center"
                              style={{ minHeight: "35px" }}
                            >
                              <div className="ff-demi">{row.header}</div>
                              <div>
                                {row.body === "title"
                                  ? row.bodyDecorator(
                                      object[row.body],
                                      object.id
                                    )
                                  : row.bodyDecorator(object[row.body])}
                              </div>
                            </div>
                          );
                        })}
                      </Card.Body>
                    </Card>
                  )}
                  {params.context === "invest" && investment && (
                    <Card className="border-0 card-chat">
                      <Card.Body className="p-0">
                        {headersInvest.map((row) => {
                          if (
                            currentUser.role === "investor" &&
                            row.body === "User"
                          ) {
                            return;
                          }
                          return (
                            <div
                              key={row.header}
                              className="d-flex justify-content-between align-items-center"
                              style={{ minHeight: "35px" }}
                            >
                              <div className="ff-demi">{row.header}</div>
                              <div
                                className={
                                  investment[row.body] ? "" : "text-secondary"
                                }
                              >
                                {row.bodyDecorator
                                  ? row.bodyDecorator(investment[row.body])
                                  : investment[row.body] || "Нет данных"}
                              </div>
                            </div>
                          );
                        })}
                      </Card.Body>
                    </Card>
                  )}
                  {!params?.context && (
                    <>
                      <div
                        className="ff-demi-22"
                        style={{
                          height: "68px",
                        }}
                      >
                        Чат с администратором
                      </div>
                    </>
                  )}
                  {params.context === "mc" && (
                    <Card className="border-0 card-chat">
                      <Card.Body className="p-0">
                        {headersMc.map((row) => (
                          <div
                            key={row.header}
                            className="d-flex justify-content-between align-items-center"
                            style={{ minHeight: "35px" }}
                          >
                            <div className="ff-demi">{row.header}</div>
                            <div>
                              {row.body === "nameCompany"
                                ? row.bodyDecorator(
                                    contextMessage[row.body],
                                    contextMessage.id
                                  )
                                : row.bodyDecorator(contextMessage[row.body])}
                            </div>
                          </div>
                        ))}
                      </Card.Body>
                    </Card>
                  )}
                  {params.context === "investor" && (
                    <Card className="border-0 card-chat">
                      <Card.Body className="p-0">
                        {headersInvestor.map((row) => (
                          <div
                            key={row.header}
                            className="d-flex justify-content-between align-items-center"
                            style={{ minHeight: "35px" }}
                          >
                            <div className="ff-demi">{row.header}</div>
                            <div
                              className={
                                contextMessage[row.body] ? "" : "text-secondary"
                              }
                            >
                              {row.bodyDecorator(contextMessage[row.body])}
                            </div>
                          </div>
                        ))}
                      </Card.Body>
                    </Card>
                  )}
                </Col>

                <Col md="8">
                  <div
                    ref={chatRef}
                    className={`${focused ? "chatFormFocused" : "chatForm"}`}
                    style={{
                      overflowY: "auto",
                      backgroundColor: "rgb(7,3,34,0.05)",
                    }}
                  >
                    <div
                      className="d-flex flex-column align-items-center justify-content-end px-3 py-1"
                      style={{
                        minHeight: `${
                          focused
                            ? "calc(100vh - 375px)"
                            : "calc(100vh - 225px)"
                        }`,
                      }}
                    >
                      {messages.length === 0 && (
                        <h5 style={{ opacity: "0.5" }}>Сообщений пока нет</h5>
                      )}
                      {Object.entries(messagesGrouped).map(
                        ([date, messages], ind) => {
                          return (
                            <div key={ind} className="w-100 d-flex flex-column">
                              <div className="p-2 align-self-center mb-2 fw-bold">
                                {date}
                              </div>
                              {messages.map((m) => {
                                return isRecipient(m) ? (
                                  <RecipientMessage key={m.id} message={m} />
                                ) : (
                                  <SenderMessage key={m.id} message={m} />
                                );
                              })}
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>

                  <Form>
                    <Form.Group className="position-relative">
                      <Form.Control
                        as="textarea"
                        value={textMessage}
                        className="rounded-0 inputChatForm"
                        placeholder="Написать сообщение..."
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onChange={(e) => setTextMessage(e.target.value)}
                      />
                      <Button
                        className="position-absolute btn-contact"
                        style={{
                          right: "5px",
                          bottom: "5px",
                          height: "40px",
                        }}
                        onClick={handleSend}
                        disabled={!textMessage}
                      >
                        Отправить
                      </Button>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
            </div>
          </div>

          {/* mobile chatForm */}
          <div className="p-2 container-scroll">
            <div
              className="container-list bg-white"
              style={{ color: "#343c4d" }}
            >
              <Row className="m-0 p-3">
                <Col sm="12">
                  <h5
                    className="m-0"
                    style={{
                      fontFamily: "Futura PT Heavy",
                      color: "#CB9722",
                    }}
                  >
                    Обратная связь{getTextContext(params?.context)}
                  </h5>
                  <hr />
                </Col>

                <Col sm="12">
                  {params.context === "object" && object && (
                    <Card className="border-0 card-chat">
                      <Card.Body className="p-0 mb-3">
                        {headersObject.map((row) => {
                          if (
                            currentUser.role === "mc" &&
                            row.body === "ManagementCompany"
                          ) {
                            return;
                          }
                          return (
                            <div
                              key={row.header}
                              className="d-flex justify-content-between align-items-center"
                              style={{ minHeight: "35px" }}
                            >
                              <div className="fw-bold">{row.header}</div>
                              <div>
                                {row.body === "title"
                                  ? row.bodyDecorator(
                                      object[row.body],
                                      object.id
                                    )
                                  : row.bodyDecorator(object[row.body])}
                              </div>
                            </div>
                          );
                        })}
                      </Card.Body>
                    </Card>
                  )}
                  {params.context === "invest" && investment && (
                    <Card className="border-0 card-chat">
                      <Card.Body className="p-0 mb-3">
                        {headersInvest.map((row) => {
                          if (
                            currentUser.role === "investor" &&
                            row.body === "User"
                          )
                            return;
                          return (
                            <div
                              key={row.header}
                              className="d-flex justify-content-between align-items-center"
                              style={{ minHeight: "35px" }}
                            >
                              <div className="fw-bold">{row.header}</div>
                              <div
                                className={
                                  investment[row.body] ? "" : "text-secondary"
                                }
                              >
                                {row.bodyDecorator(investment[row.body])}
                              </div>
                            </div>
                          );
                        })}
                      </Card.Body>
                    </Card>
                  )}
                  {!params?.context && (
                    <>
                      <div style={{ height: "68px", fontWeight: "bold" }}>
                        Чат с администратором
                      </div>
                    </>
                  )}
                  {params.context === "mc" && (
                    <Card className="border-0 card-chat">
                      <Card.Body className="p-0 mb-3">
                        {headersMc.map((row) => (
                          <div
                            key={row.header}
                            className="d-flex justify-content-between align-items-center"
                            style={{ minHeight: "35px" }}
                          >
                            <div className="fw-bold">{row.header}</div>
                            <div>
                              {row.body === "nameCompany"
                                ? row.bodyDecorator(
                                    contextMessage[row.body],
                                    contextMessage.id
                                  )
                                : row.bodyDecorator(contextMessage[row.body])}
                            </div>
                          </div>
                        ))}
                      </Card.Body>
                    </Card>
                  )}
                  {params.context === "investor" && (
                    <Card className="border-0 card-chat">
                      <Card.Body className="p-0 mb-3">
                        {headersInvestor.map((row) => (
                          <div
                            key={row.header}
                            className="d-flex justify-content-between align-items-center"
                            style={{ minHeight: "35px" }}
                          >
                            <div className="fw-bold">{row.header}</div>
                            <div
                              className={
                                contextMessage[row.body] ? "" : "text-secondary"
                              }
                            >
                              {row.bodyDecorator(contextMessage[row.body])}
                            </div>
                          </div>
                        ))}
                      </Card.Body>
                    </Card>
                  )}
                </Col>

                <Col sm="12">
                  <div
                    ref={chatMobileRef}
                    className={`${
                      focused ? "chatFormFocusedAdaptive" : "chatFormAdaptive"
                    }`}
                    style={{
                      overflowY: "auto",
                      background: "rgb(7,3,34,0.05)",
                    }}
                  >
                    <div
                      className="d-flex flex-column align-items-center justify-content-end px-3 py-1"
                      style={{
                        minHeight: `${
                          focused
                            ? "calc(100vh - 393px)"
                            : "calc(100vh - 293px)"
                        }`,
                      }}
                    >
                      {messages.length === 0 && (
                        <h5 style={{ opacity: "0.5" }}>Сообщений пока нет</h5>
                      )}
                      {Object.entries(messagesGrouped).map(
                        ([date, messages], ind) => {
                          return (
                            <div key={ind} className="w-100 d-flex flex-column">
                              <div className="p-2 align-self-center mb-2 fw-bold">
                                {date}
                              </div>
                              {messages.map((m) => {
                                return isRecipient(m) ? (
                                  <RecipientMessage key={m.id} message={m} />
                                ) : (
                                  <SenderMessage key={m.id} message={m} />
                                );
                              })}
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>

                  <Form>
                    <Form.Group className="position-relative">
                      <Form.Control
                        as="textarea"
                        value={textMessage}
                        className="rounded-0 rounded-bottom inputChatFormAdaptive"
                        placeholder="Написать сообщение..."
                        style={{ fontSize: "20px" }}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onChange={(e) => setTextMessage(e.target.value)}
                      />
                      <Button
                        className="position-absolute"
                        variant="secondary"
                        style={{
                          right: "5px",
                          bottom: "5px",
                        }}
                        onClick={handleSend}
                        disabled={!textMessage}
                      >
                        <SendIcon />
                      </Button>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
            </div>
          </div>
        </>
      );
    }
  }

  return (
    <div className="container-scroll">
      <div
        className="d-flex container-form m-auto p-0 pt-2"
        style={{
          width: "calc(100vw - 40px)",
        }}
      >
        <Spinner
          className="justify-content-center mx-auto"
          animation="border"
          variant="light"
        />
      </div>
    </div>
  );
};

export default FeedbackForm;
