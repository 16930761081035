export const normalize = (data) => {
  const newData = data.map((row) => ({ ...row.User, ...row, User: {} }));
  return newData;
};

export const normalizeMc = (data) => {
  const newData = data.map((row) => ({
    ...row.ManagementCompany,
    ...row,
    ManagementCompany: {},
  }));
  return newData;
};

export const normalizeFiles = (fileList) => {
  const data = [];

  for (let key in fileList) {
    key !== "length" && key !== "item" && data.push(fileList[key]);
  }

  return data;
};
