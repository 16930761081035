import httpService from "./http.service";

const messageService = {
  get: async () => {
    const { data } = await httpService.get(`user/chat`);
    return data;
  },
  send: async (payload) => {
    const { data } = await httpService.post(`user/chat`, payload);
    return data;
  },
  getContext: async (payload) => {
    const { data } = await httpService.get(
      `user/chat/${payload.context}/${payload.contextId}`
    );
    return data;
  },
  postContext: async (payload) => {
    const { data } = await httpService.post(
      `user/chat/${payload.params.context}/${payload.params.contextId}`,
      payload.data
    );
    return data;
  },
  getNew: async () => {
    const { data } = await httpService.get(`user/chat/new_messages`);
    return data;
  },

  getAdmin: async (payload) => {
    const { data } = await httpService.get(
      `admin/chat/${payload.context}/${payload.contextId}`
    );
    return data;
  },
  sendAdmin: async (payload) => {
    const { data } = await httpService.post(
      `admin/chat/${payload.params.context}/${payload.params.contextId}`,
      payload.data
    );
    return data;
  },
};

export default messageService;
