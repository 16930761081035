import mcService from "../services/mc.service";
import { clearItem } from "./selectedItem";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  entities: null,
  mcInfo: null,
  mcNames: null,
  isFetching: false,
  error: null,
  dataLoaded: false,
  dataUpdated: false,
};

const mcSlice = createSlice({
  name: "mc",
  initialState,
  reducers: {
    mcRequested: (state) => {
      state.error = null;
      state.isFetching = true;
      state.dataUpdated = false;
    },
    mcRequestSuccess: (state, action) => {
      state.entities = action.payload;
      state.isFetching = false;
      state.dataLoaded = true;
    },
    mcRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isFetching = false;
    },
    mcStatusSuccess: (state) => {
      state.isFetching = false;
      state.dataUpdated = true;
    },
    mcStatusFailed: (state, action) => {
      state.error = action.payload;
      state.isFetching = false;
    },
    mcInfoSuccess: (state, action) => {
      state.mcInfo = action.payload;
      state.isFetching = false;
    },
    mcNamesSuccess: (state, action) => {
      state.mcNames = action.payload;
      state.isFetching = false;
    },
  },
});

const { reducer: mcReducer, actions } = mcSlice;
const {
  mcRequested,
  mcRequestSuccess,
  mcRequestFailed,
  mcStatusSuccess,
  mcStatusFailed,
  mcInfoSuccess,
  mcNamesSuccess,
} = actions;

export const loadMcList = (payload) => async (dispatch) => {
  dispatch(mcRequested());
  try {
    const mcList = await mcService.get(payload);
    dispatch(mcRequestSuccess(mcList));
  } catch (error) {
    const { message } = error.response.data;
    dispatch(mcRequestFailed(message));
  }
};

export const loadMcNames = () => async (dispatch) => {
  dispatch(mcRequested());
  try {
    const namesMc = await mcService.getMcNames();
    dispatch(mcNamesSuccess(namesMc));
  } catch (error) {
    const { message } = error.response.data;
    dispatch(mcRequestFailed(message));
  }
};

export const loadMcInfo = (payload) => async (dispatch) => {
  dispatch(mcRequested());
  try {
    const mc = await mcService.getMcOne(payload);
    dispatch(mcInfoSuccess(mc));
    if (!mc) dispatch(mcRequestFailed("Управляющая компания не найдена"));
  } catch (error) {
    const { message } = error.response.data;
    dispatch(mcRequestFailed(message));
  }
};

export const approveMc = (payload) => async (dispatch) => {
  dispatch(mcRequested());
  dispatch(clearItem());
  try {
    await mcService.approve(payload);
    dispatch(mcStatusSuccess());
  } catch (error) {
    const { message } = error.response.data;
    dispatch(mcStatusFailed(message));
  }
};

export const rejectMc = (payload) => async (dispatch) => {
  dispatch(mcRequested());
  dispatch(clearItem());
  try {
    await mcService.reject(payload);
    dispatch(mcStatusSuccess());
  } catch (error) {
    const { message } = error.response.data;
    dispatch(mcStatusFailed(message));
  }
};

export const addObject = (payload) => async (dispatch) => {
  dispatch(mcRequested());
  try {
    await mcService.addObject(payload);
    dispatch(mcStatusSuccess());
    return true;
  } catch (error) {
    const { message } = error.response.data;
    dispatch(mcStatusFailed(message));
  }
};

export const getMcList = () => (state) => state.mc.entities;
export const getMcErrors = () => (state) => state.mc.error;
export const getMc = () => (state) => state.mc.mcInfo;
export const getMcNames = () => (state) => state.mc.mcNames;
export const getDataStatus = () => (state) => state.mc.dataLoaded;
export const getIsLoading = () => (state) => state.mc.isFetching;
export const getIsDataUpdated = () => (state) => state.mc.dataUpdated;

export default mcReducer;
