import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import TypeViewButton from "../TypeViewButton";
import { ReactComponent as HideFilter } from "../../../icons/chevron-up.svg";
import { ReactComponent as OpenFilter } from "../../../icons/chevron-down.svg";
import { ReactComponent as ClearFilter } from "../../../icons/trash.svg";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as formik from "formik";
import {
  transformToQueryParams,
  transformToFilters,
} from "../../../utils/utilityFunctions";
import { useHistory, useLocation } from "react-router-dom";
import { updateFilterInvestment } from "../../../store/investment";
import localStorageService from "../../../services/localStorage.service";
import TypeViewButtonFilter from "../TypeViewButtonFilter";

const FilterInvestment = () => {
  const history = useHistory();
  const initialFilters = {
    new: "",
    approved: "",
    leaving: "",
  };
  const [isOpenedFilter, setFilter] = useState(false);
  const [initialValues, setValues] = useState(initialFilters);
  const { Formik } = formik;
  const dispatch = useDispatch();
  const location = useLocation();

  const handleClick = () => {
    setFilter(!isOpenedFilter);
  };

  useEffect(() => {
    setValues(() => ({
      ...initialFilters,
      ...transformToFilters(location.search),
    }));

    dispatch(
      updateFilterInvestment({
        ...initialFilters,
        ...transformToFilters(location.search),
      })
    );

    // dispatch(
    //   loadInvestmentList({
    //     page: 1,
    //     pageSize: 20,
    //     ...transformToFilters(location.search),
    //   })
    // );
  }, [location.search]);

  const handleUpdate = (values) => {
    history.push(`my-investments${transformToQueryParams(values)}`);
  };

  return (
    <>
      <Formik
        validateOnBlur={false}
        onSubmit={() => handleUpdate(initialValues)}
        initialValues={initialValues}
      >
        {({ handleSubmit, handleChange, handleReset }) => (
          <Form
            onSubmit={handleSubmit}
            className="px-3 text-white"
            style={{
              fontSize: "14px",
              background:
                "linear-gradient(269.05deg, rgba(176, 108, 0, 0.6) 0%, rgba(214, 192, 43, 0.6) 25%, rgba(176, 108, 0, 0.6) 43.5%, rgba(214, 192, 43, 0.6) 79%, rgba(183, 115, 3, 0.6) 100%)",
              margin: `${
                localStorageService.getType() === "cards"
                  ? "0 5px 6px 5px"
                  : "0 0 11px 0"
              }`,
            }}
          >
            {/* classic filter */}
            <div className="form-filter position-relative">
              <Row style={{ minHeight: "64px" }}>
                <div className="my-auto">
                  <div className="d-flex justify-content-between">
                    <Form.Group className="d-flex align-items-center me-3">
                      <div className="p-2 label-filter">Поданные заявки:</div>
                      <Form.Select
                        name="new"
                        value={initialValues.new}
                        onChange={(e) => {
                          handleChange(e);
                          handleUpdate({
                            ...initialValues,
                            new: e.target.value,
                          });
                        }}
                        className="input-filter drop-custom input-filter-select"
                        style={{ width: "150px" }}
                      >
                        <option value="" style={{ color: "grey" }}>
                          Выбрать...
                        </option>
                        <option value="true">Да</option>
                        <option value="false">Нет</option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group className="d-flex align-items-center me-3">
                      <div className="p-2 label-filter">
                        Одобренные инвестиции:
                      </div>
                      <Form.Select
                        name="approved"
                        value={initialValues.approved}
                        onChange={(e) => {
                          handleChange(e);
                          handleUpdate({
                            ...initialValues,
                            approved: e.target.value,
                          });
                        }}
                        className="input-filter drop-custom input-filter-select"
                        style={{ width: "150px" }}
                      >
                        <option value="" style={{ color: "grey" }}>
                          Выбрать...
                        </option>
                        <option value="true">Да</option>
                        <option value="false">Нет</option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group className="d-flex align-items-center me-3">
                      <div className="p-2 label-filter">
                        Проекты, из которых клиент хочет выйти:
                      </div>
                      <Form.Select
                        name="leaving"
                        value={initialValues.leaving}
                        onChange={(e) => {
                          handleChange(e);
                          handleUpdate({
                            ...initialValues,
                            leaving: e.target.value,
                          });
                        }}
                        className="input-filter drop-custom input-filter-select"
                        style={{ width: "150px" }}
                      >
                        <option value="" style={{ color: "grey" }}>
                          Выбрать...
                        </option>
                        <option value="true">Да</option>
                        <option value="false">Нет</option>
                      </Form.Select>
                    </Form.Group>

                    <div className="d-flex align-items-center">
                      <Button
                        variant="link"
                        className="link-light btn-filter"
                        onClick={() => {
                          handleReset();
                          handleUpdate(initialFilters);
                        }}
                      >
                        <ClearFilter />
                      </Button>

                      <TypeViewButtonFilter />
                    </div>
                  </div>
                </div>
              </Row>

              <Button type="submit" className="d-none"></Button>
            </div>

            {/* adaptive filter */}
            <div className="form-filter-compact">
              <Row style={{ height: "64px" }}>
                <div className="col col-xs-10 my-auto text-white ff-demi-22">
                  Фильтры
                </div>
                <Form.Group
                  as={Col}
                  xs="2"
                  className="d-flex justify-content-end align-items-center"
                >
                  <Button
                    variant="link"
                    className="link-light btn-filter"
                    onClick={() => {
                      handleReset();
                      handleUpdate(initialFilters);
                    }}
                  >
                    <ClearFilter />
                  </Button>

                  <TypeViewButtonFilter />

                  <Button
                    variant="link"
                    className="link-light btn-filter"
                    onClick={handleClick}
                  >
                    {!isOpenedFilter && <OpenFilter />}
                    {isOpenedFilter && <HideFilter />}
                  </Button>
                </Form.Group>
              </Row>

              <Row className={`pb-2 ${isOpenedFilter ? "" : "d-none"}`}>
                <hr className="p-0 m-0 mb-2" />

                <Form.Group
                  as={Col}
                  sm="12"
                  className="d-flex align-items-center"
                  style={{ height: "42px" }}
                >
                  <div className="p-2 col col-sm-8 label-filter">
                    Поданные заявки:
                  </div>
                  <Col>
                    <Form.Select
                      name="new"
                      value={initialValues.new}
                      onChange={(e) => {
                        handleChange(e);
                        handleUpdate({
                          ...initialValues,
                          new: e.target.value,
                        });
                      }}
                      className="input-filter drop-custom input-filter-select"
                    >
                      <option value="" style={{ color: "grey" }}>
                        Выбрать...
                      </option>
                      <option value="true">Да</option>
                      <option value="false">Нет</option>
                    </Form.Select>
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Col}
                  sm="12"
                  className="d-flex align-items-center"
                  style={{ height: "42px" }}
                >
                  <div className="p-2 col col-sm-8 label-filter">
                    Одобренные инвестиции:
                  </div>
                  <Col>
                    <Form.Select
                      name="approved"
                      value={initialValues.approved}
                      onChange={(e) => {
                        handleChange(e);
                        handleUpdate({
                          ...initialValues,
                          approved: e.target.value,
                        });
                      }}
                      className="input-filter drop-custom input-filter-select"
                    >
                      <option value="" style={{ color: "grey" }}>
                        Выбрать...
                      </option>
                      <option value="true">Да</option>
                      <option value="false">Нет</option>
                    </Form.Select>
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Col}
                  sm="12"
                  className="d-flex align-items-center"
                  style={{ height: "42px" }}
                >
                  <div className="p-2 col col-sm-8 label-filter">
                    Проекты, из которых клиент хочет выйти:
                  </div>
                  <Col>
                    <Form.Select
                      name="leaving"
                      value={initialValues.leaving}
                      onChange={(e) => {
                        handleChange(e);
                        handleUpdate({
                          ...initialValues,
                          leaving: e.target.value,
                        });
                      }}
                      className="input-filter drop-custom input-filter-select"
                    >
                      <option value="" style={{ color: "grey" }}>
                        Выбрать...
                      </option>
                      <option value="true">Да</option>
                      <option value="false">Нет</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FilterInvestment;
