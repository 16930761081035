import Button from "react-bootstrap/Button";
import { ReactComponent as Grid } from "../../icons/filter-cards.svg";
import { ReactComponent as Table } from "../../icons/filter-table.svg";
import { useDispatch, useSelector } from "react-redux";
import { changeViewData, getTypeView } from "../../store/selectedItem";

const TypeViewButtonFilter = () => {
  const typeView = useSelector(getTypeView());
  const dispatch = useDispatch();

  return (
    <>
      {typeView === "cards" && (
        <div className="d-flex justify-content-end mx-2 btn-filter">
          <Button
            variant="link"
            className="link-light"
            style={{
              height: "42px",
              width: "42px",
            }}
            onClick={() => dispatch(changeViewData("table"))}
          >
            <Table />
          </Button>
        </div>
      )}

      {typeView === "table" && (
        <div className="d-flex justify-content-end mx-2 btn-filter">
          <Button
            variant="link"
            className="link-light"
            style={{
              height: "42px",
              width: "42px",
              paddingLeft: "14px",
            }}
            onClick={() => dispatch(changeViewData("cards"))}
          >
            <Grid />
          </Button>
        </div>
      )}
    </>
  );
};

export default TypeViewButtonFilter;
