import Button from "react-bootstrap/Button";
import { Link, useLocation } from "react-router-dom";

const MenuAdmin = ({ offcanvas }) => {
  const location = useLocation();

  if (!offcanvas)
    return (
      <div>
        <Button
          variant="link"
          className={`me-3 p-0 ${
            location.pathname === "/mc-request" ? "btn-menu-active" : "btn-menu"
          }
          `}
        >
          <Link className="link-menu" to="/mc-request">
            УК
          </Link>
        </Button>

        <Button
          variant="link"
          className={`me-3 p-0 ${
            location.pathname === "/investor-request"
              ? "btn-menu-active"
              : "btn-menu"
          }
          `}
        >
          <Link className="link-menu" to="/investor-request">
            Инвесторы
          </Link>
        </Button>

        <Button
          variant="link"
          className={`me-3 p-0 ${
            location.pathname === "/object-request"
              ? "btn-menu-active"
              : "btn-menu"
          }
          `}
        >
          <Link className="link-menu" to="/object-request">
            Объекты
          </Link>
        </Button>

        <Button
          variant="link"
          className={`p-0 ${
            location.pathname === "/investment-request"
              ? "btn-menu-active"
              : "btn-menu"
          }
          `}
        >
          <Link className="link-menu" to="/investment-request">
            Инвестирование
          </Link>
        </Button>
      </div>
    );
  return (
    <>
      <Link className="nav-link-offcanvas" to="/mc-request">
        УК
      </Link>
      <Link className="nav-link-offcanvas" to="/investor-request">
        Инвесторы
      </Link>
      <Link className="nav-link-offcanvas" to="/object-request">
        Объекты
      </Link>
      <Link className="nav-link-offcanvas" to="/investment-request">
        Инвестирование
      </Link>
    </>
  );
};

export default MenuAdmin;
