import { normalizeNumber } from "../../../utils/utilityFunctions";
import { Link } from "react-router-dom";

export const headers = [
  {
    header: "Номер заявки",
    body: "id",
    bodyDecorator: (id) => (
      <div className="p-1">
        <Link
          to={`/object/${id}`}
          style={{ color: "#CB9722", fontFamily: "Futura PT Heavy" }}
        >
          ID: {id}
        </Link>
      </div>
    ),
  },
  {
    header: "Мин.объём инвестиций",
    body: "minInvest",
    bodyDecorator: (minInvest) => (
      <div className="m-0 p-1" style={{ fontFamily: "Futura PT Heavy" }}>
        {normalizeNumber(minInvest)} ₽
      </div>
    ),
  },
  {
    header: "Доходность годовых",
    body: "profit",
    bodyDecorator: (profit) => (
      <div
        className="m-0 p-1"
        style={{ color: "#cb9722", fontFamily: "Futura PT Heavy" }}
      >
        {profit} %
      </div>
    ),
  },
  {
    header: "Срок запуска проекта",
    body: "dateStart",
    bodyDecorator: (dateStart) => (
      <div className="p-1" style={{ fontFamily: "Futura PT Heavy" }}>
        {dateStart} мес.
      </div>
    ),
  },
];
