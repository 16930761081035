import { Link } from "react-router-dom";
import { normalizeNumber } from "../../../utils/utilityFunctions";
import localStorageService from "../../../services/localStorage.service";
import Button from "react-bootstrap/Button";

export const headers = [
  {
    header: "Номер заявки",
    body: "id",
    bodyDecorator: (id) =>
      localStorageService.getType() === "cards" ? (
        <Link to={`/object/${id}`} className="p-0 ff-heavy-22">
          ID: {id}
        </Link>
      ) : (
        <div className="p-2">
          <Link to={`/object/${id}`} className="ff-heavy-link">
            ID: {id}
          </Link>
        </div>
      ),
  },
  {
    header: "Мин.объём инвестиций",
    body: "minInvest",
    bodyDecorator: (minInvest) => {
      return localStorageService.getType() === "cards" ? (
        <div className="m-0 p-0 ff-heavy" style={{ fontSize: "22px" }}>
          {normalizeNumber(minInvest)} ₽
        </div>
      ) : (
        <div className="p-2 pe-0 ff-demi">{normalizeNumber(minInvest)} ₽</div>
      );
    },
  },
  {
    header: "Доходность годовых",
    body: "profit",
    bodyDecorator: (profit) => {
      return localStorageService.getType() === "cards" ? (
        <div className="m-0 p-0 ff-heavy-22">{profit} %</div>
      ) : (
        <div className="td-profit ff-heavy-link">{profit} %</div>
      );
    },
  },
  {
    header: "Срок запуска проекта",
    body: "dateStart",
    bodyDecorator: (dateStart) => (
      <div className="p-2 pe-0">{dateStart} мес.</div>
    ),
  },
  {
    header: "Тип проекта",
    body: "typeProject",
    bodyDecorator: (item) => (
      <Button
        variant="light"
        style={{
          backgroundColor: "transparent",
          border: 0,
          color: "#343C4D",
          cursor: "auto",
          fontSize: `${
            localStorageService.getType() === "cards" ? "14px" : "15px"
          }`,
        }}
        className={`p-2 pe-0 ${
          localStorageService.getType() === "cards" ? "ff-demi" : "ff-medium"
        }`}
      >
        {item}
      </Button>
    ),
  },
  {
    header: "В собственности",
    body: "owner",
    bodyDecorator: (owner) => (
      <div className="p-2 pe-0">{owner ? "Да" : "Нет"}</div>
    ),
  },
  {
    header: "Возможность продажи",
    body: "sale",
    bodyDecorator: (sale) => (
      <div className="p-2 pe-0">{sale ? "Да" : "Нет"}</div>
    ),
  },
  {
    header: "Статус",
    body: "status",
    bodyDecorator: (status) => {
      if (status === "new")
        return (
          <div
            className={`text-center ${
              localStorageService.getType() === "cards"
                ? "td-status-notbg"
                : "td-status"
            }`}
          >
            Новый
          </div>
        );
      if (status === "working")
        return (
          <div
            className={`text-center ${
              localStorageService.getType() === "cards"
                ? "td-status-notbg"
                : "td-status"
            }`}
          >
            На рассмотрении
          </div>
        );
      if (status === "updated")
        return (
          <div
            className={`text-center ${
              localStorageService.getType() === "cards"
                ? "td-status-notbg"
                : "td-status"
            }`}
          >
            Повторное рассмотрение
          </div>
        );
      if (status === "approved")
        return (
          <div
            className={`text-center ${
              localStorageService.getType() === "cards"
                ? "td-status-notbg"
                : "td-status"
            }`}
          >
            Одобрен
          </div>
        );
      if (status === "rejected")
        return (
          <div
            className={`text-center ${
              localStorageService.getType() === "cards"
                ? "td-status-notbg"
                : "td-status"
            }`}
          >
            Отклонен
          </div>
        );
      if (status === "deleted")
        return (
          <div
            className={`text-center ${
              localStorageService.getType() === "cards"
                ? "td-status-notbg"
                : "td-status"
            }`}
          >
            Удален
          </div>
        );
    },
    // bodyDecorator: (status) => {
    //   if (status === "new") return "Новый";
    //   if (status === "working") return "На рассмотрении";
    //   if (status === "updated") return "Повторное рассмотрение";
    //   if (status === "approved") return "Одобрен";
    //   if (status === "rejected") return "Отклонен";
    //   if (status === "deleted") return "Удален";
    // },
  },
];
