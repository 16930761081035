import React from "react";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import * as formik from "formik";
import * as yup from "yup";
import { ReactComponent as Eye } from "../../../icons/eye-fill.svg";
import { ReactComponent as EyeSlash } from "../../../icons/eye-slash-fill.svg";
import { ReactComponent as File } from "../../../icons/icon-file.svg";
import { ReactComponent as FileResetIcon } from "../../../icons/x-circle.svg";
import "../../../App.css";
import { Link, useHistory } from "react-router-dom";
import YupPassword from "yup-password";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../store/modal";
import {
  getIsLoggedIn,
  getUsersFetchingStatus,
  signUpMc,
} from "../../../store/users";
import ErrorTooltip from "../../common/ErrorTooltip";
import LabelCustom from "../../common/LabelCustom";
import Spinner from "react-bootstrap/Spinner";
import { getTypeProjectList } from "../../../store/enum";
import Col from "react-bootstrap/Col";
import localStorageService from "../../../services/localStorage.service";
YupPassword(yup);

const RegisterMc = () => {
  const { Formik } = formik;
  const isLoggedIn = useSelector(getIsLoggedIn());
  const options = useSelector(getTypeProjectList());
  const isGlobalLoading = useSelector(getUsersFetchingStatus());
  const history = useHistory();
  const [selectedValue, setValue] = useState([]);

  const schema = yup.object().shape({
    email: yup
      .string()
      .required("Введите эл. почту")
      .email("Введите эл. почту"),
    password: yup
      .string()
      .required("Введите пароль")
      // .test(
      //   "numbers",
      //   "Допустимы только цифры и лат.буквы",
      //   (value) => !value || /^[0-9a-zA-Z]+$/.test(value)
      // )
      .password()
      .min(8, "Пароль должен содержать не менее 8 символов")
      .minLowercase(1, "Пароль должен содержать хотя бы 1 строчную букву")
      .minUppercase(1, "Пароль должен содержать хотя бы 1 заглавную букву")
      .minNumbers(1, "Пароль должен содержать хотя бы 1 цифру")
      .minSymbols(0),
    phone: yup
      .string()
      .required("Введите номер телефона")
      .password()
      .min(11, "Номер должен начинаться с 8")
      .max(11, "Некорректный номер")
      .minNumbers(11, "Пароль должен состоять из цифр")
      .minSymbols(0)
      .minLowercase(0, "Пароль должен содержать хотя бы 1 строчную букву")
      .minUppercase(0, "Пароль должен содержать хотя бы 1 заглавную букву"),
    presentation: yup.mixed().required("Загрузите файл"),
    employeePosition: yup.string().required("Введите должность представителя"),
    employeeName: yup.string().required("Введите ФИО представителя"),
    nameCompany: yup.string().required("Введите название компании"),
    typeProject: yup
      .array()
      .required("Введите тип проекта")
      .test("len", "Выберите тип проектов", (arr) => arr.length > 0),

    // .test("len", "Выберите тип проектов", (arr) => arr.length > 0)
    // .test("is-null", "Выберите тип проектов", (arr) => arr !== null),
    numberProject: yup
      .string()
      .test(
        "numbers",
        "Допустимы только цифры",
        (value) => !value || /^[0-9]+$/.test(value)
      )
      .required("Введите число проектов"),
    site: yup.string().required("Введите сайт компании"),
    inn: yup
      .string()
      .test(
        "numbers",
        "Допустимы только цифры",
        (value) => !value || /^[0-9]+$/.test(value)
      )
      .required("Введите ИНН"),
  });

  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleSignUp = async (data) => {
    return await dispatch(signUpMc(data));
  };

  if (options)
    return (
      <div className="auth-theme">
        <div
          style={{
            paddingTop: "100px",
            paddingBottom: "80px",
            overflow: "auto",
            height: "100vh",
          }}
        >
          <div className="container d-flex align-items-center justify-content-center">
            <div
              className="wrapper-mc bg-white rounded-0"
              style={{
                opacity: `${
                  isGlobalLoading && localStorageService.getRefreshToken()
                    ? "0"
                    : "1"
                }`,
              }}
            >
              <Formik
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={schema}
                onSubmit={async (values, { resetForm }) => {
                  const isReset = await handleSignUp(values);
                  if (isReset) {
                    resetForm();
                    setValue([]);
                    dispatch(
                      openModal({
                        body: "Ваша заявка на регистрацию успешно отправлена",
                        type: "auth",
                      })
                    );
                  }
                }}
                // onSubmit={(values) => console.log(values)}
                initialValues={{
                  inn: "",
                  employeeName: "",
                  employeePosition: "",
                  phone: "",
                  email: "",
                  password: "",
                  presentation: "",
                  nameCompany: "",
                  numberProject: "",
                  site: "",
                  typeProject: [],
                  terms: true,
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  setFieldValue,
                  values,
                  errors,
                }) => (
                  <Form className="p-5" onSubmit={handleSubmit}>
                    <Col className="align-items-center flex-column mc-signup-form">
                      <h2
                        className="text-center mb-4"
                        style={{ maxWidth: "500px" }}
                      >
                        Зарегистрировать аккаунт управляющей компании
                      </h2>

                      <div className="w-100">
                        <div className="d-flex justify-content-space-between row">
                          <Col className="py-0 m-0" xs="12" md="4">
                            <Form.Group className="mb-4 position-relative">
                              <LabelCustom children={"Название"} />
                              <Form.Control
                                type="text"
                                name="nameCompany"
                                value={values.nameCompany}
                                onChange={handleChange}
                                placeholder="Введите название"
                                className="input-auth"
                              />
                              <ErrorTooltip children={errors.nameCompany} />
                            </Form.Group>

                            <Form.Group className="mb-4 position-relative">
                              <LabelCustom children={"ИНН"} />
                              <Form.Control
                                type="text"
                                name="inn"
                                value={values.inn}
                                onChange={handleChange}
                                placeholder="Введите ИНН"
                                className="input-auth"
                              />
                              <ErrorTooltip children={errors.inn} />
                            </Form.Group>

                            <Form.Group className="mb-4 position-relative">
                              <LabelCustom children={"Сайт компании"} />
                              <Form.Control
                                type="text"
                                name="site"
                                value={values.site}
                                onChange={handleChange}
                                placeholder="Введите адрес сайта"
                                className="input-auth"
                              />
                              <ErrorTooltip children={errors.site} />
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Button
                                className="p-0 w-100 position-relative btn btn-load"
                                variant="light"
                              >
                                <div
                                  className="position-absolute top-50 start-50 translate-middle"
                                  style={{
                                    whiteSpace: "nowrap",
                                    color: "#cb9722",
                                    fontFamily: "Futura PT Heavy",
                                  }}
                                >
                                  Загрузить презентацию
                                </div>
                                <Form.Control
                                  type="file"
                                  name="presentation"
                                  accept=".jpeg, .jpg, .png, .pdf, .ppt"
                                  style={{ opacity: "0" }}
                                  onChange={(event) => {
                                    setFieldValue(
                                      "presentation",
                                      event.target.files[0]
                                    );
                                  }}
                                  className="input-auth"
                                />
                              </Button>

                              <div className="position-relative">
                                <ErrorTooltip children={errors.presentation} />
                              </div>
                            </Form.Group>

                            {values.presentation !== "" &&
                              values.presentation !== undefined && (
                                <div
                                  className="d-inline-block position-relative"
                                  title={`${
                                    values.presentation.name ||
                                    values.presentation.originalName
                                  }`}
                                >
                                  <File />
                                  <div
                                    className="position-absolute ff-demi"
                                    style={{
                                      fontSize: "10px",
                                      bottom: "10px",
                                      left: "10px",
                                      userSelect: "none",
                                    }}
                                  >
                                    {values.presentation.type.split("/")[1]}
                                  </div>
                                  <Button
                                    className="position-absolute translate-middle badge rounded-pill border-0 p-0"
                                    style={{
                                      top: "18%",
                                      right: "-20%",
                                      padding: "0px",
                                      opacity: "0",
                                    }}
                                    onClick={() => {
                                      setFieldValue("presentation", "");
                                    }}
                                  >
                                    <FileResetIcon />
                                  </Button>
                                </div>
                              )}
                          </Col>

                          <Col className="py-0 m-0" xs="12" md="4">
                            <Form.Group className="mb-4 position-relative">
                              <LabelCustom children={"Тип проектов"} z={1} />
                              <Select
                                placeholder="Выберите типы проектов"
                                isMulti
                                value={selectedValue}
                                name="typeProject"
                                options={options}
                                onChange={(data) => {
                                  setValue(data);
                                  setFieldValue(
                                    "typeProject",
                                    data.map((item) => item.label)
                                  );
                                }}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                styles={{
                                  menu: (styles) => ({
                                    ...styles,
                                    zIndex: "3",
                                    color: "#343c4d",
                                    fontFamily: "Futura PT Demi",
                                    fontSize: "16px",
                                  }),
                                  control: (styles) => ({
                                    ...styles,
                                    minHeight: "48px",
                                    borderRadius: "0",
                                    borderColor: "rgb(222, 226, 230)",
                                    ":hover": {
                                      borderColor: "rgb(222, 226, 230)",
                                      cursor: "pointer",
                                    },
                                  }),
                                  placeholder: (styles) => ({
                                    ...styles,
                                    color: "#343c4d",
                                    opacity: "0.4",
                                    fontFamily: "Futura PT Demi",
                                    fontSize: "16px",
                                  }),
                                }}
                              />
                              <ErrorTooltip children={errors.typeProject} />
                            </Form.Group>

                            <Form.Group className="mb-4 position-relative">
                              <LabelCustom children={"ФИО представителя"} />
                              <Form.Control
                                type="text"
                                name="employeeName"
                                value={values.employeeName}
                                onChange={handleChange}
                                placeholder="Введите ФИО"
                                className="input-auth"
                              />
                              <ErrorTooltip children={errors.employeeName} />
                            </Form.Group>

                            <Form.Group className="mb-4 position-relative">
                              <LabelCustom children={"Телефон представителя"} />
                              <Form.Control
                                type="text"
                                name="phone"
                                value={values.phone}
                                onChange={handleChange}
                                placeholder="Введите телефон"
                                className="input-auth"
                              />
                              <ErrorTooltip children={errors.phone} />
                            </Form.Group>

                            <Form.Group className="mb-4 position-relative">
                              <LabelCustom children={"Должность"} />
                              <Form.Control
                                type="text"
                                name="employeePosition"
                                value={values.employeePosition}
                                onChange={handleChange}
                                placeholder="Введите должность"
                                className="input-auth"
                              />
                              <ErrorTooltip
                                children={errors.employeePosition}
                              />
                            </Form.Group>

                            <Button
                              className="btn-auth w-100 mb-3"
                              type="submit"
                              style={{
                                height: "48px",
                              }}
                              disabled={!values.terms}
                            >
                              Зарегистрироваться
                            </Button>

                            <div className="d-flex justify-content-center px-2 pb-3">
                              <input
                                type="checkbox"
                                className="custom-checkbox"
                                id="terms1"
                                name="terms"
                                defaultChecked
                                onChange={handleChange}
                              />
                              <label
                                className="custom-checkbox-label ff-demi"
                                htmlFor="terms1"
                                style={{ opacity: "0.7" }}
                              >
                                Я принимаю условия Пользовательского соглашения
                                и даю свое согласие на обработку моей
                                персональной информации на условиях,
                                определенной Политикой конфиденциальности
                              </label>
                            </div>

                            <p
                              className="text-center ff-demi"
                              style={{
                                fontSize: "14px",
                              }}
                            >
                              Уже есть аккаунт?&nbsp;
                              <Link to={`/login`} className="link-auth">
                                Войти
                              </Link>
                            </p>
                          </Col>

                          <Col className="py-0 m-0" xs="12" md="4">
                            <Form.Group className="mb-4 position-relative">
                              <LabelCustom children={"E-mail"} />
                              <Form.Control
                                type="text"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                placeholder="Введите E-mail"
                                className="input-auth"
                              />
                              <ErrorTooltip children={errors.email} />
                            </Form.Group>

                            <Form.Group className="mb-4 position-relative">
                              <LabelCustom
                                children={"Количество успешных проектов"}
                              />
                              <Form.Control
                                type="text"
                                name="numberProject"
                                value={values.numberProject}
                                onChange={handleChange}
                                placeholder="Введите количество"
                                className="input-auth"
                              />
                              <ErrorTooltip children={errors.numberProject} />
                            </Form.Group>

                            <Form.Group className="mb-4">
                              <div className="position-relative">
                                <LabelCustom children={"Пароль"} />
                                <Form.Control
                                  type={showPassword ? "text" : "password"}
                                  name="password"
                                  value={values.password}
                                  onChange={handleChange}
                                  placeholder="Введите пароль"
                                  className="input-auth"
                                />
                                <Button
                                  variant="link"
                                  className="p-0 position-absolute end-0 bottom-0 translate-middle link-secondary"
                                  onClick={toggleShowPassword}
                                >
                                  {!showPassword && <Eye />}
                                  {showPassword && <EyeSlash />}
                                </Button>
                                <ErrorTooltip children={errors.password} />
                              </div>
                            </Form.Group>
                          </Col>
                        </div>
                      </div>
                    </Col>

                    <Col className="align-items-center flex-column mc-signup-form-adaptive">
                      <h2
                        className="text-center mb-4"
                        style={{ maxWidth: "500px" }}
                      >
                        Зарегистрировать аккаунт управляющей компании
                      </h2>

                      <div className="w-100">
                        <div className="d-flex justify-content-space-between row">
                          <Col className="py-0 m-0" xs="12" md="4">
                            <Form.Group className="mb-4 position-relative">
                              <LabelCustom children={"Название"} />
                              <Form.Control
                                type="text"
                                name="nameCompany"
                                value={values.nameCompany}
                                onChange={handleChange}
                                placeholder="Введите название"
                                className="input-auth"
                              />
                              <ErrorTooltip children={errors.nameCompany} />
                            </Form.Group>

                            <Form.Group className="mb-4 position-relative">
                              <LabelCustom children={"ИНН"} />
                              <Form.Control
                                type="text"
                                name="inn"
                                value={values.inn}
                                onChange={handleChange}
                                placeholder="Введите ИНН"
                                className="input-auth"
                              />
                              <ErrorTooltip children={errors.inn} />
                            </Form.Group>

                            <Form.Group className="mb-4 position-relative">
                              <LabelCustom children={"Сайт компании"} />
                              <Form.Control
                                type="text"
                                name="site"
                                value={values.site}
                                onChange={handleChange}
                                placeholder="Введите адрес сайта"
                                className="input-auth"
                              />
                              <ErrorTooltip children={errors.site} />
                            </Form.Group>

                            <Form.Group className="mb-4">
                              <Button className="p-0 w-100 position-relative btn btn-load">
                                <div
                                  className="position-absolute top-50 start-50 translate-middle"
                                  style={{
                                    whiteSpace: "nowrap",
                                    color: "#cb9722",
                                    fontFamily: "Futura PT Demi",
                                  }}
                                >
                                  Загрузить презентацию
                                </div>
                                <Form.Control
                                  type="file"
                                  name="presentation"
                                  accept=".jpeg, .jpg, .png, .pdf, .ppt"
                                  style={{ opacity: "0" }}
                                  className="input-auth"
                                  onChange={(event) => {
                                    setFieldValue(
                                      "presentation",
                                      event.target.files[0]
                                    );
                                  }}
                                />
                              </Button>

                              <div className="position-relative">
                                <ErrorTooltip children={errors.presentation} />
                              </div>
                            </Form.Group>

                            {values.presentation !== "" &&
                              values.presentation !== undefined && (
                                <div
                                  className="d-inline-block position-relative mb-4"
                                  title={`${
                                    values.presentation.name ||
                                    values.presentation.originalName
                                  }`}
                                >
                                  <File />
                                  <div
                                    className="position-absolute ff-demi"
                                    style={{
                                      fontSize: "10px",
                                      bottom: "10px",
                                      left: "10px",
                                      userSelect: "none",
                                    }}
                                  >
                                    {values.presentation.type.split("/")[1]}
                                  </div>
                                  <Button
                                    className="position-absolute translate-middle badge rounded-pill border-0 p-0"
                                    style={{
                                      top: "18%",
                                      right: "-20%",
                                      padding: "0px",
                                      opacity: "0",
                                    }}
                                    onClick={() => {
                                      setFieldValue("presentation", "");
                                    }}
                                  >
                                    <FileResetIcon />
                                  </Button>
                                </div>
                              )}
                          </Col>

                          <Col className="py-0 m-0" xs="12" md="4">
                            <Form.Group className="mb-4 position-relative">
                              <LabelCustom children={"Тип проектов"} z={1} />
                              <Select
                                placeholder="Выберите типы проектов"
                                isMulti
                                value={selectedValue}
                                name="typeProject"
                                options={options}
                                onChange={(data) => {
                                  setValue(data);
                                  setFieldValue(
                                    "typeProject",
                                    data.map((item) => item.label)
                                  );
                                }}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                styles={{
                                  menu: (styles) => ({
                                    ...styles,
                                    zIndex: "3",
                                    color: "#343c4d",
                                    fontFamily: "Futura PT Demi",
                                    fontSize: "16px",
                                  }),
                                  control: (styles) => ({
                                    ...styles,
                                    minHeight: "48px",
                                    borderRadius: "0",
                                    borderColor: "rgb(222, 226, 230)",
                                    ":hover": {
                                      borderColor: "rgb(222, 226, 230)",
                                      cursor: "pointer",
                                    },
                                  }),
                                  placeholder: (styles) => ({
                                    ...styles,
                                    color: "#343c4d",
                                    opacity: "0.4",
                                    fontFamily: "Futura PT Demi",
                                    fontSize: "16px",
                                  }),
                                }}
                              />
                              <ErrorTooltip children={errors.typeProject} />
                            </Form.Group>

                            <Form.Group className="mb-4 position-relative">
                              <LabelCustom children={"ФИО представителя"} />
                              <Form.Control
                                type="text"
                                name="employeeName"
                                value={values.employeeName}
                                onChange={handleChange}
                                placeholder="Введите ФИО"
                                className="input-auth"
                              />
                              <ErrorTooltip children={errors.employeeName} />
                            </Form.Group>

                            <Form.Group className="mb-4 position-relative">
                              <LabelCustom children={"Телефон представителя"} />
                              <Form.Control
                                type="text"
                                name="phone"
                                value={values.phone}
                                onChange={handleChange}
                                placeholder="Введите телефон"
                                className="input-auth"
                              />
                              <ErrorTooltip children={errors.phone} />
                            </Form.Group>

                            <Form.Group className="mb-4 position-relative">
                              <LabelCustom children={"Должность"} />
                              <Form.Control
                                type="text"
                                name="employeePosition"
                                value={values.employeePosition}
                                onChange={handleChange}
                                placeholder="Введите должность"
                                className="input-auth"
                              />
                              <ErrorTooltip
                                children={errors.employeePosition}
                              />
                            </Form.Group>
                          </Col>

                          <Col className="py-0 m-0" xs="12" md="4">
                            <Form.Group className="mb-4 position-relative">
                              <LabelCustom children={"E-mail"} />
                              <Form.Control
                                type="text"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                placeholder="Введите E-mail"
                                className="input-auth"
                              />
                              <ErrorTooltip children={errors.email} />
                            </Form.Group>

                            <Form.Group className="mb-4 position-relative">
                              <LabelCustom
                                children={"Количество успешных проектов"}
                              />
                              <Form.Control
                                type="text"
                                name="numberProject"
                                value={values.numberProject}
                                onChange={handleChange}
                                placeholder="Введите количество"
                                className="input-auth"
                              />
                              <ErrorTooltip children={errors.numberProject} />
                            </Form.Group>

                            <Form.Group className="mb-4">
                              <div className="position-relative">
                                <LabelCustom children={"Пароль"} />
                                <Form.Control
                                  type={showPassword ? "text" : "password"}
                                  name="password"
                                  value={values.password}
                                  onChange={handleChange}
                                  placeholder="Введите пароль"
                                  className="input-auth"
                                />
                                <Button
                                  variant="link"
                                  className="p-0 position-absolute end-0 bottom-0 translate-middle link-secondary"
                                  onClick={toggleShowPassword}
                                >
                                  {!showPassword && <Eye />}
                                  {showPassword && <EyeSlash />}
                                </Button>
                                <ErrorTooltip children={errors.password} />
                              </div>
                            </Form.Group>
                          </Col>
                        </div>
                      </div>

                      <Col xs="12" md="4">
                        <Button
                          className="btn-auth w-100 mb-3"
                          type="submit"
                          style={{
                            height: "48px",
                          }}
                          disabled={!values.terms}
                        >
                          Зарегистрироваться
                        </Button>

                        <div className="d-flex justify-content-center px-2 pb-3">
                          <input
                            type="checkbox"
                            className="custom-checkbox"
                            id="terms2"
                            name="terms"
                            defaultChecked
                            onChange={handleChange}
                          />
                          <label
                            className="custom-checkbox-label ff-demi"
                            htmlFor="terms2"
                            style={{ opacity: "0.7" }}
                          >
                            Я принимаю условия Пользовательского соглашения и
                            даю свое согласие на обработку моей персональной
                            информации на условиях, определенной Политикой
                            конфиденциальности
                          </label>
                        </div>

                        <p
                          className="text-center ff-demi"
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          Уже есть аккаунт?&nbsp;
                          <Link to={`/login`} className="link-auth">
                            Войти
                          </Link>
                        </p>
                      </Col>
                    </Col>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <>
      {!isGlobalLoading && (
        <div className="container-scroll">
          <div
            className="d-flex container-form m-auto p-0 pt-2"
            style={{
              width: "calc(100vw - 40px)",
            }}
          >
            <Spinner
              className="justify-content-center m-auto"
              animation="border"
              variant="light"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default RegisterMc;
