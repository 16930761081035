import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedItem, select } from "../../store/selectedItem";
import { useEffect } from "react";
import ImageObject from "./ImageObject";
import { getCurrentUser } from "../../store/users";
import { useLocation } from "react-router-dom";

const TypeCards = ({ rows, list, role = null }) => {
  const clicked = useSelector(getSelectedItem());
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser());
  const location = useLocation();

  const handleClick = ({ id, role, name, email, status, titleObject }) => {
    const isRepeat =
      clicked?.id === id
        ? null
        : { id, role, name, email, status, titleObject };
    dispatch(select(isRepeat));
  };

  useEffect(() => {
    dispatch(select(null));
  }, []);

  if (currentUser)
    return (
      <>
        <Row sm={1} md={2} lg={3} xl={3} xxl={4} className="g-0 m-auto">
          {list.map((item) => (
            <Col key={item.id}>
              {(currentUser.role === "admin" ||
                currentUser.role === "mc" ||
                (currentUser.role === "investor" &&
                  location.pathname === "/my-investments")) && (
                <Card
                  className={`rounded-0 ${
                    clicked?.id === item.id && "border-warning"
                  }`}
                  onClick={(e) => {
                    if (
                      e.target.tagName !== "BUTTON" &&
                      e.target.tagName !== "A"
                    )
                      handleClick({
                        id: item.id,
                        role: item.role || role,
                        name: item.nameCompany,
                        email: item.email,
                        status: item.status,
                        titleObject: item?.Object?.title,
                      });
                  }}
                  style={{
                    fontSize: "16px",
                    minWidth: "320px",
                    color: "#343c4d",
                    fontWeight: "600",
                    margin: "2px 2px",
                    border: "4px solid rgb(7,3,33)",
                  }}
                >
                  {role === "object" && <ImageObject item={item} />}

                  <Card.Body style={{ padding: "2px 0" }}>
                    {rows.map((row) => (
                      <div
                        key={row.header}
                        className={`d-flex justify-content-between align-items-center px-3 ${
                          row.header === "Доходность годовых" && "card-profit"
                        }`}
                        style={{ minHeight: "40px", fontSize: "14px" }}
                      >
                        <div className="ff-book">{row.header}</div>
                        <div
                          // className={`${
                          //   item[row.body] ? "" : "text-secondary"
                          // }`}
                          className={`ff-demi ${
                            item[row.body] === null || item[row.body] === ""
                              ? "text-secondary"
                              : ""
                          }`}
                        >
                          {row.bodyDecorator
                            ? row.body === "title"
                              ? row.bodyDecorator(item[row.body], item.id)
                              : row.bodyDecorator(item[row.body])
                            : item[row.body] || "Нет данных"}
                        </div>
                      </div>
                    ))}
                  </Card.Body>
                </Card>
              )}
              {currentUser.role === "investor" &&
                location.pathname !== "/my-investments" && (
                  <Card
                    className="rounded-0"
                    style={{
                      fontSize: "16px",
                      minWidth: "320px",
                      color: "#343c4d",
                      fontWeight: "600",
                      margin: "2px 2px",
                      border: "4px solid rgb(7,3,33)",
                    }}
                  >
                    {role === "object" && <ImageObject item={item} />}

                    <Card.Body style={{ padding: "2px 0" }}>
                      {rows.map((row) => (
                        <div
                          key={row.header}
                          className={`d-flex justify-content-between align-items-center px-3 ${
                            row.header === "Доходность годовых" && "card-profit"
                          }`}
                          style={{ minHeight: "40px", fontSize: "14px" }}
                        >
                          <div className="ff-book">{row.header}</div>
                          <div
                            className={`ff-demi ${
                              item[row.body] === null || item[row.body] === ""
                                ? "text-secondary"
                                : ""
                            }`}
                          >
                            {row.bodyDecorator(item[row.body])}
                          </div>
                        </div>
                      ))}
                    </Card.Body>
                  </Card>
                )}
            </Col>
          ))}
        </Row>
      </>
    );
};

export default TypeCards;
