import { getTime } from "../../../utils/utilityFunctions";

const SenderMessage = ({ message }) => {
  return (
    <div
      className="p-3 bg-white mb-2 align-self-start"
      style={{
        maxWidth: "75%",
        minWidth: "80px",
      }}
    >
      <div className="message-user" style={{ fontSize: "14px" }}>
        {message.message}
      </div>

      <div className="text-end" style={{ fontSize: "13px" }}>
        {getTime(message.createdAt)}
      </div>
    </div>
  );
};

export default SenderMessage;
