import { Link } from "react-router-dom";
import { normalizeNumber } from "../../../utils/utilityFunctions";
import Button from "react-bootstrap/Button";

export const headers = [
  {
    header: "Номер заявки",
    body: "id",
    bodyDecorator: (id) => (
      <div className="p-2">
        <Link to={`/object/${id}`} className="ff-heavy-link">
          ID: {id}
        </Link>
      </div>
    ),
  },
  {
    header: "Мин.объём инвестиций",
    body: "minInvest",
    bodyDecorator: (minInvest) => (
      <div className="p-2 ff-demi">{normalizeNumber(minInvest)} ₽</div>
    ),
  },
  {
    header: "Доходность годовых",
    body: "profit",
    bodyDecorator: (profit) => (
      <div className="td-profit ff-heavy-link">{profit} %</div>
    ),
  },
  {
    header: "Срок запуска проекта",
    body: "dateStart",
    bodyDecorator: (dateStart) => <div className="p-2">{dateStart} мес.</div>,
  },
  {
    header: "Тип проекта",
    body: "typeProject",
    bodyDecorator: (item) => (
      <Button
        variant="light"
        style={{
          backgroundColor: "transparent",
          border: 0,
          color: "#343C4D",
          cursor: "auto",
          fontSize: "15px",
        }}
        className="p-2"
      >
        {item}
      </Button>
    ),
  },
  {
    header: "В собственности",
    body: "owner",
    bodyDecorator: (owner) => <div className="p-2">{owner ? "Да" : "Нет"}</div>,
  },
  {
    header: "Возможность продажи",
    body: "sale",
    bodyDecorator: (sale) => <div className="p-2">{sale ? "Да" : "Нет"}</div>,
  },
  {
    header: "Статус",
    body: "status",
    bodyDecorator: (status) => {
      if (status === "new")
        return <div className="text-center td-status">Новый</div>;
      if (status === "working")
        return <div className="text-center td-status">На рассмотрении</div>;
      if (status === "updated")
        return (
          <div className="text-center td-status">Повторное рассмотрение</div>
        );
      if (status === "approved")
        return <div className="text-center td-status">Одобрен</div>;
      if (status === "rejected")
        return <div className="text-center td-status">Отклонен</div>;
      if (status === "deleted")
        return <div className="text-center td-status">Удален</div>;
    },
    // bodyDecorator: (status) => {
    //   if (status === "new") return "Новый";
    //   if (status === "working") return "На рассмотрении";
    //   if (status === "updated") return "Повторное рассмотрение";
    //   if (status === "approved") return "Одобрен";
    //   if (status === "rejected") return "Отклонен";
    //   if (status === "deleted") return "Удален";
    // },
  },
];
