import React, { useState } from "react";
import "../../App.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser, getIsLoggedIn, logOut } from "../../store/users";
import { ReactComponent as FavoritesIcon } from "../../icons/favorites.svg";
import { ReactComponent as NotificationIcon } from "../../icons/notification.svg";
import { ReactComponent as NotificationIconMessage } from "../../icons/notification-message.svg";
import { ReactComponent as DropdownArrow } from "../../icons/navbar-downbutton.svg";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import Container from "react-bootstrap/Container";
import Menu from "./Menu";
import MenuContact from "./MenuContact";
import { openModal } from "../../store/modal";
import NavLink from "react-bootstrap/NavLink";
import { pathUserRole } from "../../utils/pathUserRole";
import { getNewMessages } from "../../store/messages";

const NavBar = () => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn());
  const currentUser = useSelector(getCurrentUser());
  const newMessages = useSelector(getNewMessages());

  const handleLogout = () => {
    dispatch(logOut());
  };

  const handleProfile = () => {
    dispatch(openModal({ body: {}, type: "profile" }));
  };

  const handleNotifications = () => {
    dispatch(openModal({ body: {}, type: "notifications" }));
  };

  const handleShow = () => {
    setShow((prevState) => !prevState);
  };

  if (isLoggedIn && currentUser)
    return (
      <>
        {/* navbar classic */}
        <Navbar
          sticky="top"
          className="navbar-classic"
          style={{
            height: "79px",
            backgroundColor: "rgba(255,255,255, 0.05)",
          }}
        >
          <Container fluid>
            <Menu role={currentUser.role} offcanvas={false} />
            <Nav className="align-items-center">
              <MenuContact role={currentUser.role} />
              {/* <Link
                to={pathUserRole(currentUser)}
                className="nav-link py-0 ff-demi"
              > */}
              <div
                className="nav-link py-0 ff-demi"
                style={{ cursor: "default" }}
              >
                <div
                  style={{
                    fontSize: "10px",
                    color: "#BEBEBE",
                  }}
                >
                  Твой аккаунт
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    color: "#BEBEBE",
                  }}
                >
                  {currentUser.email}
                </div>
              </div>
              {/* </Link> */}

              {/* <Link disabled className="nav-link" to="/">
                <FavoritesIcon />
              </Link> */}

              <Button
                className="p-1 bg-transparent"
                style={{
                  border: "none",
                  color: "rgb(222, 226, 230)",
                }}
              >
                <div style={{ width: "30px" }}>
                  <FavoritesIcon />
                </div>
              </Button>

              <Button
                className="p-1 bg-transparent"
                style={{
                  border: "none",
                  color: "rgb(222, 226, 230)",
                  cursor: `${newMessages?.length <= 0 ? "auto" : "pointer"}`,
                }}
                onClick={() => {
                  if (newMessages?.length > 0) handleNotifications();
                }}
                // disabled={!newMessages?.length}
              >
                <div style={{ width: "30px" }}>
                  {newMessages?.length <= 0 && <NotificationIcon />}
                  {newMessages?.length > 0 && <NotificationIconMessage />}
                  {/* <NotificationIcon />
                  {newMessages?.length > 0 && (
                    <span
                      className="position-absolute border border-dark rounded-circle"
                      style={{
                        backgroundColor: "#1BDCC2",
                        top: "2px",
                        right: "-2px",
                        padding: "4px",
                      }}
                    />
                  )} */}
                </div>
              </Button>

              <Dropdown>
                <div className="position-relative">
                  <Dropdown.Toggle
                    className="px-3 ms-3"
                    variant="secondary"
                    id="dropdown-basic"
                    style={{ height: "50px", opacity: "0" }}
                  />

                  <DropdownArrow
                    className="position-absolute top-0 end-0"
                    onClick={() => {
                      document.getElementById("dropdown-basic").click();
                    }}
                  />
                </div>

                <Dropdown.Menu className="dropdown-menu dropdown-menu-end rounded-0 mt-0">
                  <Dropdown.Item onClick={handleProfile}>
                    Редактировать профиль
                  </Dropdown.Item>
                  <NavDropdown.Divider />
                  <Dropdown.Item onClick={handleLogout}>Выход</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Container>
        </Navbar>

        {/* navbar offcanvas */}
        <Navbar
          expand="xxl"
          className="navbar-offcanvas"
          sticky="top"
          style={{
            height: "79px",
            backgroundColor: "rgba(255,255,255, 0.05)",
          }}
        >
          <Container fluid>
            <Navbar.Toggle
              variant="light"
              className="shadow-none border-0"
              aria-controls="offcanvasNavbar-expand-xxl"
              onClick={handleShow}
            />
            <Nav className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <MenuContact role={currentUser.role} />
                {/* <Link
                  className="nav-link profile-user ff-demi"
                  to={pathUserRole(currentUser)}
                > */}
                <div
                  className="nav-link profile-user py-0 ff-demi"
                  style={{ cursor: "default" }}
                >
                  <div
                    style={{
                      fontSize: "10px",
                      color: "#BEBEBE",
                    }}
                  >
                    Твой аккаунт
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      color: "#BEBEBE",
                    }}
                  >
                    {currentUser.email}
                  </div>
                </div>
                {/* </Link> */}
              </div>
            </Nav>

            <Navbar.Offcanvas
              id="offcanvasNavbar-expand-xxl"
              aria-labelledby="offcanvasNavbarLabel-expand-xxl"
              placement="start"
              scroll="false"
              show={show}
              onHide={handleShow}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id="offcanvasNavbarLabel-expand-xxl" />
              </Offcanvas.Header>
              <Offcanvas.Body
                style={{ color: "#343c4d", fontFamily: "Futura PT Demi" }}
              >
                <Nav className="" onClick={handleShow}>
                  <div
                    style={{
                      cursor: "default",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "10px",
                      }}
                    >
                      Твой аккаунт
                    </div>
                    <div>{currentUser.email}</div>
                  </div>
                  <hr />
                  <NavLink onClick={handleProfile}>
                    Редактировать профиль
                  </NavLink>

                  <Button
                    className="ps-0 bg-transparent d-flex"
                    style={{
                      border: "none",
                      color: "#343c4d",
                    }}
                  >
                    <div className="me-2">
                      <FavoritesIcon />
                    </div>
                    Избранное
                  </Button>

                  {/* <Link className="nav-link d-flex" to="/">
                    <div className="position-relative me-2">
                      <FavoritesIcon />
                      <span
                        className="position-absolute border border-dark rounded-circle"
                        style={{
                          backgroundColor: "#1BDCC2",
                          top: "2px",
                          right: "-2px",
                          padding: "4px",
                        }}
                      />
                    </div>
                    Избранное
                  </Link> */}

                  <Button
                    className="ps-0 bg-transparent d-flex"
                    style={{
                      border: "none",
                      color: "#343c4d",
                      cursor: `${
                        newMessages?.length <= 0 ? "auto" : "pointer"
                      }`,
                    }}
                    onClick={() => {
                      if (newMessages?.length > 0) handleNotifications();
                    }}
                    // disabled={newMessages?.length === 0}
                  >
                    <div className="me-2">
                      {newMessages?.length <= 0 && <NotificationIcon />}
                      {newMessages?.length > 0 && <NotificationIconMessage />}
                    </div>
                    Оповещения
                  </Button>

                  <hr />
                  <Menu role={currentUser.role} offcanvas={true} />
                  <hr />
                  <NavLink onClick={handleLogout}>Выход</NavLink>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </>
    );

  return;
};

export default NavBar;
