import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Preloader from "./components/hoc/Preloader";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from "./components/layout/Login";
import RegisterAdmin from "./components/layout/register/RegisterAdmin";
import Main from "./components/layout/Main";
import Toasts from "./components/common/Toasts";
import RecoveryPasswordRequest from "./components/layout/recoveryPassword/RecoveryPasswordRequest";
import ResetPassword from "./components/layout/recoveryPassword/ResetPassword";
import ModalCustom from "./components/common/ModalCustom";
import ProtectedRoute from "./components/common/ProtectedRoute";
import AppLoader from "./components/hoc/AppLoader";
import Profile from "./components/ui/Profile";
import McList from "./components/ui/mc/McList";
import RegisterInvestor from "./components/layout/register/RegisterInvestor";
import InvestorList from "./components/ui/investor/InvestorList";
import RegisterMc from "./components/layout/register/RegisterMc";
import NavBar from "./components/ui/NavBar";
import ObjectList from "./components/ui/adminUser/ObjectList";
import McObjectList from "./components/ui/mc/McObjectList";
import AddObject from "./components/ui/mc/AddObject";
import McRequests from "./components/ui/mc/McRequests";
import EditProfileObject from "./components/ui/object/EditProfileObject";
import ProfileObject from "./components/ui/object/ProfileObject";
import Notifications from "./components/common/feedback/Notifications";
import Chat from "./components/common/feedback/Chat";
import McCard from "./components/ui/mc/McCard";
import AdminInvestmentList from "./components/ui/adminUser/AdminInvestmentList";
import InvestmentList from "./components/ui/investor/InvestmentList";
import AuthRoute from "./components/common/AuthRoute";
import McInvestmentList from "./components/ui/mc/McInvestmentList";
import AuthorizedRoute from "./components/common/AuthorizedRoute";
import InvestorRoute from "./components/common/InvestorRoute";
import McRoute from "./components/common/McRoute";
import Footer from "./components/ui/Footer";
import WelcomePage from "./components/layout/WelcomePage";

function App() {
  return (
    <>
      <Preloader />
      <AppLoader>
        <NavBar />
        <Profile />
        <Notifications />
        <Switch>
          <AuthRoute path="/welcome-page" component={WelcomePage} />
          <AuthRoute path="/login" component={Login} />
          <AuthRoute path="/admin/signup" component={RegisterAdmin} />
          <AuthRoute path="/mc/signup" component={RegisterMc} />
          <AuthRoute path="/investor/signup" component={RegisterInvestor} />
          <AuthRoute
            path="/recovery-password-request"
            component={RecoveryPasswordRequest}
          />
          <AuthRoute path="/recovery-password" component={ResetPassword} />
          <McRoute path="/add-object" component={AddObject} />
          <McRoute path="/my-objects" component={McObjectList} />
          <McRoute path="/investments" component={McInvestmentList} />
          <McRoute path="/object" exact component={McRequests} />
          <McRoute
            path="/object/edit/:objectId?"
            component={EditProfileObject}
          />
          <AuthorizedRoute
            path="/object/:objectId?"
            component={ProfileObject}
          />
          <AuthorizedRoute path="/object-request" component={ObjectList} />
          <AuthorizedRoute path="/mc/:mcId?" component={McCard} />
          <AuthorizedRoute
            path="/chat/:context?/:contextId?"
            component={Chat}
          />
          <InvestorRoute path="/my-investments" component={InvestmentList} />
          <ProtectedRoute path="/mc-request" component={McList} />
          <ProtectedRoute path="/investor-request" component={InvestorList} />
          <ProtectedRoute
            path="/investment-request"
            component={AdminInvestmentList}
          />

          <Route path="/" exact component={Main} />
          <Redirect from="*" to="/" />
        </Switch>
        <Footer />
      </AppLoader>
      <Toasts />
      <ModalCustom />
    </>
  );
}

export default App;
