import React, { useState } from "react";
import "../../../App.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import * as formik from "formik";
import * as yup from "yup";
import YupPassword from "yup-password";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsersFetchingStatus,
  recoveryPasswordRequest,
} from "../../../store/users";
import { openModal } from "../../../store/modal";
import ErrorTooltip from "../../common/ErrorTooltip";
import LabelCustom from "../../common/LabelCustom";
import { useHistory } from "react-router-dom";
import localStorageService from "../../../services/localStorage.service";
YupPassword(yup);

const RecoveryPasswordRequest = () => {
  const { Formik } = formik;
  const isLoading = useSelector(getUsersFetchingStatus());
  const history = useHistory();

  const schema = yup.object().shape({
    email: yup
      .string()
      .required("Введите эл. почту")
      .email("Введите эл. почту"),
  });

  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  const handleRequest = async (data) => {
    setEmail(data.email);
    return await dispatch(recoveryPasswordRequest(data));
  };

  return (
    <div className="auth-theme">
      <div className="container d-flex align-items-center justify-content-center vh-100">
        <div
          className="wrapper bg-white rounded-0"
          style={{
            opacity: `${
              isLoading && localStorageService.getRefreshToken() ? "0" : "1"
            }`,
          }}
        >
          <Formik
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={schema}
            onSubmit={async (values, { resetForm }) => {
              const isReset = await handleRequest(values);
              if (isReset) {
                resetForm();
                dispatch(
                  openModal({
                    body: `На вашу почту ${email} отправлено письмо для восстановления пароля`,
                    type: "auth",
                  })
                );
              }
            }}
            initialValues={{
              email: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
            }) => (
              <Form
                className="p-5 d-flex align-items-center flex-column"
                onSubmit={handleSubmit}
              >
                <h2 className="text-center mb-4">Восстановить пароль</h2>

                <div className="form__data w-100">
                  <Form.Group
                    className="position-relative"
                    controlId="validationFormik01"
                  >
                    <LabelCustom children={"E-mail"} />
                    <Form.Control
                      type="text"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Введите E-mail"
                      className="input-auth"
                    />
                    {touched.email && <ErrorTooltip children={errors.email} />}
                  </Form.Group>

                  <Button
                    className="btn-auth w-100"
                    style={{ height: "48px", marginTop: "23px" }}
                    type="submit"
                  >
                    Отправить пароль на e-mail
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default RecoveryPasswordRequest;
