export const checkChangeData = (values, initialValues) => {
  const result = {};

  for (const key in initialValues) {
    if (key === "photo" || key === "document" || key === "presentation") {
      if (
        values[key][0]?.lastModified &&
        values[key][0]?.name !== initialValues[key][0].originalName
      )
        result[key] = values[key];
      else if (key === "photo" || key === "document") {
        const isChanged = initialValues[key].filter(
          (value, i) => value.id !== values[key][i]?.id
        );
        if (isChanged.length > 0) result[key] = isChanged;
      }
    } else if (initialValues[key] !== values[key]) {
      if (key === "typeProject" && typeof initialValues[key] !== "string") {
        if (values[key].length !== initialValues[key].length) {
          result[key] = values[key];
        } else {
          const matched = [];

          values[key].map((item1) => {
            initialValues[key].map((item2) => {
              if (item1.value === item2.value) {
                matched.push(item1);
              }
            });
          });

          if (matched.length !== values[key].length) result[key] = values[key];
        }

        // const isChanged = initialValues[key].filter(
        //   (value, i) => value.label !== values[key][i]
        // );

        // if (
        //   isChanged.length > 0 ||
        //   initialValues[key].length !== values[key].length
        // )
        //   result[key] = isChanged;
      } else {
        result[key] = values[key];
      }
    }
  }

  return Object.keys(result).length;
};

export const checkChangeDataObject = (values, initialValues) => {
  let isChanged = false;
  for (const key in initialValues) {
    if (
      initialValues[key] !== values[key] &&
      !["photo", "document"].includes(key)
    ) {
      isChanged = true;
    } else if (["photo", "document"].includes(key)) {
      if (initialValues[key].length !== values[key].length) {
        isChanged = true;
      } else {
        for (const item of initialValues[key]) {
          let isEqual = false;

          for (const itemValues of values[key]) {
            if (
              !(item?.id === itemValues?.id || item?.name === itemValues?.name)
            ) {
              isEqual = true;
            }
          }

          if (isEqual) isChanged = true;
        }
      }
    }
  }

  return isChanged;
};

// export const updatedData = (values, initialValues) => {
//   const result = {};
//   for (const key in initialValues) {
//     if (key === "photo" || key === "document" || key === "presentation") {
//       if (values[key][0]?.lastModified) result[key] = values[key];
//       else if (key === "photo" || key === "document") {
//         const isChanged = initialValues[key].filter(
//           (value, i) => value.id !== values[key][i]?.id
//         );
//         if (isChanged.length > 0) result[key] = isChanged;
//       }
//     } else if (initialValues[key] !== values[key]) result[key] = values[key];
//   }

//   return result;
// };

export const updatedData = (values, initialValues) => {
  const result = {};

  for (const key in initialValues) {
    if (
      initialValues[key] !== values[key] &&
      !["photo", "document"].includes(key)
    ) {
      result[key] = values[key];
    } else if (["photo", "document"].includes(key)) {
      result[key] = values[key].filter((item) => {
        if (!item?.id) return item;
      });
    }
  }

  return result;
};

export const updatedDataMcProfile = (values, initialValues) => {
  const result = {};

  for (const key in initialValues) {
    if (
      initialValues[key] !== values[key] &&
      !["photo", "document", "typeProject"].includes(key)
    ) {
      result[key] = values[key];
    } else if (["photo", "document"].includes(key)) {
      result[key] = values[key].filter((item) => {
        if (!item?.id) return item;
      });
    } else if (key === "typeProject") {
      if (values[key].length !== initialValues[key].length) {
        result[key] = values[key].map((item) => item.label);
      } else {
        const matched = [];

        values[key].map((item1) => {
          initialValues[key].map((item2) => {
            if (item1.value === item2.value) {
              matched.push(item1);
            }
          });
        });

        if (matched.length !== values[key].length)
          result[key] = values[key].map((item) => item.label);
      }
    }
  }

  return result;
};
