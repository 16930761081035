import investorService from "../services/investor.service";
import { clearItem } from "./selectedItem";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  entities: null,
  investorInfo: null,
  isFetching: false,
  error: null,
  dataLoaded: false,
  dataUpdated: false,
};

const investorSlice = createSlice({
  name: "investor",
  initialState,
  reducers: {
    investorRequested: (state) => {
      state.error = null;
      state.isFetching = true;
      state.dataUpdated = false;
    },
    investorRequestSuccess: (state, action) => {
      state.entities = action.payload;
      state.isFetching = false;
      state.dataLoaded = true;
    },
    investorRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isFetching = false;
    },
    investorStatusSuccess: (state) => {
      state.isFetching = false;
      state.dataUpdated = true;
    },
    investorStatusFailed: (state, action) => {
      state.error = action.payload;
      state.isFetching = false;
    },
    investorInfoSuccess: (state, action) => {
      state.investorInfo = action.payload;
      state.isFetching = false;
    },
  },
});

const { reducer: investorReducer, actions } = investorSlice;
const {
  investorRequested,
  investorRequestSuccess,
  investorRequestFailed,
  investorStatusSuccess,
  investorStatusFailed,
  investorInfoSuccess,
} = actions;

export const loadInvestorList = (payload) => async (dispatch) => {
  dispatch(investorRequested());
  try {
    const list = await investorService.get(payload);
    dispatch(investorRequestSuccess(list));
  } catch (error) {
    const { message } = error.response.data;
    dispatch(investorRequestFailed(message));
  }
};

export const approveInvestor = (payload) => async (dispatch) => {
  dispatch(investorRequested());
  dispatch(clearItem());
  try {
    await investorService.approve(payload);
    dispatch(investorStatusSuccess());
  } catch (error) {
    const { message } = error.response.data;
    dispatch(investorStatusFailed(message));
  }
};

export const rejectInvestor = (payload) => async (dispatch) => {
  dispatch(investorRequested());
  dispatch(clearItem());
  try {
    await investorService.reject(payload);
    dispatch(investorStatusSuccess());
  } catch (error) {
    const { message } = error.response.data;
    dispatch(investorStatusFailed(message));
  }
};

export const loadInvestorInfo = (payload) => async (dispatch) => {
  dispatch(investorRequested());
  try {
    const investor = await investorService.getInvestorOne(payload);
    dispatch(investorInfoSuccess(investor));
    if (!investor) dispatch(investorRequestFailed("Чат не найден"));
  } catch (error) {
    const { message } = error.response.data;
    dispatch(investorRequestFailed(message));
  }
};

export const getInvestorList = () => (state) => state.investor.entities;
export const getInvestorError = () => (state) => state.investor.error;
export const getDataStatus = () => (state) => state.investor.dataLoaded;
export const getIsLoadingInvestor = () => (state) => state.investor.isFetching;
export const getIsDataUpdated = () => (state) => state.investor.dataUpdated;
export const getInvestor = () => (state) => state.investor.investorInfo;

export default investorReducer;
