export const normalizeNumber = (objectNumber) => {
  const length = objectNumber.length;
  let number = "";
  for (let i = 1; i <= Math.floor(length / 3); i++) {
    number =
      " " + objectNumber.slice(length - 3 * i, length - 3 * (i - 1)) + number;
  }
  if (length % 3)
    number =
      objectNumber.slice(0, length - 3 * Math.floor(length / 3)) + number;

  return number;
};

export const transformToQueryParams = (filters) => {
  let searchLine = "?";

  for (const f in filters) {
    if (filters[f] !== "" && f !== "page" && f !== "pageSize") {
      searchLine = searchLine + f + "=" + filters[f] + "&";
    }
  }

  return searchLine.slice(0, -1);
};

export const transformToFilters = (searchLine) => {
  const filters = searchLine.slice(1).split("&");
  const result = {};

  filters
    .filter((f) => f !== "")
    .forEach((pair) => {
      const res = pair.split("=");
      result[res[0]] = res[1];
    });
  return result;
};

export const splitByKey = (arr, key) => {
  return arr.reduce((o, e) => {
    let val = e[key];
    if (val) {
      if (!o[val]) o[val] = [];
      o[val].push(e);
    }
    return o;
  }, {});
};

export const sortById = (arr) => {
  arr.filter((item) => item);

  return arr.reduce((o, e) => {
    let object = e["objectId"];
    let invest = e["investId"];

    if (object && e?.object && e?.user.role === "mc") {
      if (!o[`Объект: ${e.object.title} (${e.mc.nameCompany})`])
        o[`Объект: ${e.object.title} (${e.mc.nameCompany})`] = [];
      o[`Объект: ${e.object.title} (${e.mc.nameCompany})`].push(e);
    } else if (object && e?.object && e?.user.role === "admin") {
      if (!o[`Объект: ${e.object.title}`]) o[`Объект: ${e.object.title}`] = [];
      o[`Объект: ${e.object.title}`].push(e);
    } else if (invest && e?.investment && e?.user.role === "investor") {
      if (
        !o[
          `Инвестиция: ${e.investment.Object.title}/${
            e.user.name || e.user.email
          }`
        ]
      )
        o[
          `Инвестиция: ${e.investment.Object.title}/${
            e.user.name || e.user.email
          }`
        ] = [];
      o[
        `Инвестиция: ${e.investment.Object.title}/${
          e.user.name || e.user.email
        }`
      ].push(e);
    } else if (invest && e?.investment && e?.user.role === "admin") {
      if (!o[`Инвестиция: ${e.investment.Object.title}`])
        o[`Инвестиция: ${e.investment.Object.title}`] = [];
      o[`Инвестиция: ${e.investment.Object.title}`].push(e);
    } else {
      if (e.user.role === "mc") {
        if (!o[`УК: ${e.mc.nameCompany} (${e.user.email})`])
          o[`УК: ${e.mc.nameCompany} (${e.user.email})`] = [];
        o[`УК: ${e.mc.nameCompany} (${e.user.email})`].push(e);
      } else if (e.user.role === "investor") {
        if (
          !o[`Инвестор: ${e.user.name || "Имя не указано"} (${e.user.email})`]
        )
          o[`Инвестор: ${e.user.name || "Имя не указано"} (${e.user.email})`] =
            [];
        o[
          `Инвестор: ${e.user.name || "Имя не указано"} (${e.user.email})`
        ].push(e);
      } else {
        if (!o[`Админ`]) o[`Админ`] = [];
        o[`Админ`].push(e);
      }
    }

    return o;
  }, {});
};

export const transformToDataParams = (message) => {
  const { objectId, investId, user, mc } = message;
  console.log(objectId, investId, user);

  if (objectId) return `/chat/object/${objectId}`;
  if (investId) return `/chat/invest/${investId}`;
  if (user.role === "mc") return `/chat/mc/${mc.id}`;
  if (user.role === "investor") return `/chat/investor/${user.id}`;
  if (user.role === "admin") return `/chat`;
};

export const toObjectByKey = (arr, key = "id") =>
  arr.reduce((o, e) => {
    o[e[key]] = e;
    return o;
  }, {});

export const getDate = (ISO) => {
  return ((date) =>
    `${String(date.getDate()).padStart(2, "0")}.${String(
      date.getMonth() + 1
    ).padStart(2, "0")}.${date.getFullYear()}`)(new Date(ISO));
};

export const getTime = (ISO) => {
  return ((date) =>
    `${String(date.getHours()).padStart(2, "0")}:${String(
      date.getMinutes()
    ).padStart(2, "0")}`)(new Date(ISO));
};

export const getTextContext = (context) => {
  if (context === "object") return ` по объекту`;
  if (context === "invest") return ` по инвестиции`;
  if (context === "mc") return ` с УК`;
  if (context === "investor") return ` с инвестором`;
};
